import { api } from "../../config/api";
import { appendToFormData } from "../../helpers/form";

const basePath = "certificates";

/**
 * Get all
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAll = () => {

	return api.get(`${basePath}/current`);

};

/**
 * Import
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
 export const importCertificate = (options) => {
	const formData = new FormData();
	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key]);
		}

	}

	return api.post(`${basePath}/import`, formData);
};
