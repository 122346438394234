import { osName, browserName } from "./../helpers/client";

// -----------------------------------------------------------------------------
// General
// -----------------------------------------------------------------------------
export const IS_DEBUG    = process.env.NODE_ENV === 'development';
export const CLIENT_DATA = {
	os_name     : osName(),
	browser_name: browserName(),
};

// -----------------------------------------------------------------------------
// API
// -----------------------------------------------------------------------------
// export const API_URL = IS_DEBUG ? "https://api.byponto.com.br/api/v1/admin/" : "https://api.byponto.com.br/api/v1/admin/";
// export const API_URL = IS_DEBUG ? "http://api.byponto.local/api/v1/admin/" : "https://api.byponto.com.br/api/v1/admin/";
export const API_URL = `${process.env.REACT_APP_API_URL}/api/v1/admin/`;

// -----------------------------------------------------------------------------
// Errors
// -----------------------------------------------------------------------------
export const API_ERRO_TYPE_VALIDATION   = "validation";
export const API_ERRO_TYPE_API          = "api";
export const API_ERRO_TYPE_SERVER       = "server";
export const API_ERRO_TYPE_CONNECTION   = "connection";
export const API_ERRO_TYPE_OTHER        = "other";
export const API_ERRO_TYPE_ACCESS_TOKEN = "access_token";
export const API_ERRO_TYPE_CANCEL       = "cancel";
