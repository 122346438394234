import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button, Dropdown, Menu, Modal, Spin, Tag } from "antd";
import QueueAnim from "rc-queue-anim";

import moment from "moment";

import { generalActions } from "./../../redux/actions";

import { professionalDeletedService } from "./../../redux/services";

import {
	UIPageListing,
} from "./../../components";

import ModalShow from "./show";
import ModalFilters from "./filters";
import TenantModalShow from "./../Tenants/show";
import TenantUserFunctionModalShow from "./../TenantUserFunctions/show";
import TenantUserTypeModalShow from "./../TenantUserTypes/show";

const config = {
	title                              : "Profissionais excluídos",
	permissionPrefix                   : "professionals",
	tenantsPermissionPrefix            : "tenants-deleted",
	tenantUserFunctionsPermissionPrefix: "tenant-user-functions",
	tenantUserTypesPermissionPrefix    : "tenant-user-types",
	list                               : "professionals-deleted",
	searchPlaceholder                  : "Buscar por nome ou e-mail",
	orders                             : [
		{
			label  : "Mais recentes",
			field  : "id",
			sort   : "desc",
			default: true,
		},
		{
			label: "Mais antigos",
			field: "id",
			sort : "asc",
		},
		{
			label: "Nome A|Z",
			field: "name",
			sort : "asc",
		},
		{
			label: "Nome Z|A",
			field: "name",
			sort : "desc",
		},
	],
};

class Index extends Component {
	constructor(props) {
		super(props);

		const defaultOrder = config.orders.find(o => o.default);

		this.state = {
			isLoading   : false,
			listType    : "list",
			data        : [],
			pagination  : {
				current : 1,
				pageSize: 20,
				total   : 0,
			},
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
			search      : "",
			// Actions
			showModalVisible   : false,
			exportModalVisible : false,
			filtersModalVisible: false,
			activeLoadings     : [],
			isExporting        : false,
			// Filters
			filters     : {
				tenant_id  : null,
				type_id    : null,
				function_id: null,
				deleted_at : null,
				created_at : null,
			},
			// Relations
			tenantShowScreen            : false,
			tenantUserFunctionShowScreen: false,
			tenantUserTypeShowScreen    : false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if( props.listType && state.listType !== props.listType )
		{
			return {
				listType: props.listType
			};
		}

		return null;
	}

	componentDidMount() {
		// Fetch all
		this.fetchGetAll(true);
	};

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<i className="fal fa-file" />Visualizar
				</a>
			</Menu.Item>}
		</Menu>
	);

	columns = () => {
		const listTypeCard = this.state.listType === "card";

		return [
			{
				title    : "ID",
				className: "id",
				visible  : !listTypeCard,
				render   : (item) => <span title={item.uuid}>{item.uuid}</span>,
			},
			{
				title : "Nome",
				render: (item) => {
					let contentFunction = <div><span style={{marginRight: 5}}>Função:</span> -</div>;
					let contentType     = <div><span style={{marginRight: 5}}>Tipo:</span> -</div>;

					// Function
					if( item.user_function )
					{
						if( item.user_function.deleted_at )
						{
							contentFunction = (
								<div>
									<span style={{marginRight: 5}}>Função:</span>
									<Tag style={{margin: "0 0 5px"}} color="#fa5c7c" title="Deletado">
										{item.user_function.name}
									</Tag>
								</div>
							);
						}
						else
						{
							if( this.props.permissions.includes(config.tenantUserFunctionsPermissionPrefix + ".show") )
							{
								contentFunction = (
									<div>
										<span style={{marginRight: 5}}>Função:</span>
										{item.user_function.is_active ? (
											<a onClick={() => this.tenantUserFunctionShowOpen(item.user_function)}>{item.user_function.name}</a>
										) : (
											<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
												<a onClick={() => this.tenantUserFunctionShowOpen(item.user_function)}>{item.user_function.name}</a>
											</Tag>
										)}
									</div>
								);
							}
							else
							{
								contentFunction = (
									<div>
										<span style={{marginRight: 5}}>Função:</span>
										{item.user_function.is_active ? item.user_function.name : (
											<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
												{item.user_function.name}
											</Tag>
										)}
									</div>
								);
							}
						}
					}

					// Type
					if( item.user_type )
					{
						if( item.user_type.deleted_at )
						{
							contentType = (
								<div>
									<span style={{marginRight: 5}}>Tipo:</span>
									<Tag style={{margin: "0 0 5px"}} color="#fa5c7c" title="Deletado">
										{item.user_type.name}
									</Tag>
								</div>
							);
						}
						else
						{
							if( this.props.permissions.includes(config.tenantUserTypesPermissionPrefix + ".show") )
							{
								contentType = (
									<div>
										<span style={{marginRight: 5}}>Tipo:</span>
										{item.user_type.is_active ? (
											<a onClick={() => this.tenantUserTypeShowOpen(item.user_type)}>{item.user_type.name}</a>
										) : (
											<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
												<a onClick={() => this.tenantUserTypeShowOpen(item.user_type)}>{item.user_type.name}</a>
											</Tag>
										)}
									</div>
								);
							}
							else
							{
								contentType = (
									<div>
										<span style={{marginRight: 5}}>Tipo:</span>
										{item.user_type.is_active ? item.user_type.name : (
											<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
												{item.user_type.name}
											</Tag>
										)}
									</div>
								);
							}
						}
					}

					return <Fragment>
						{listTypeCard ? <h3>{item.name}</h3> : <div style={{marginBottom: 4, fontWeight: 600}}>{item.name}</div>}
						{contentFunction}
						{contentType}
					</Fragment>;
				},
			},
			{
				title    : "E-mail",
				className: "no-padding-horizontal",
				render   : (item) => item.email,
			},
			{
				title : "Empresa",
				width : 220,
				render: (item) => {
					let content = "";

					if( item.tenant )
					{
						if( this.props.permissions.includes(config.tenantsPermissionPrefix + ".show") )
						{
							content = item.tenant.is_active ? (
								<a onClick={() => this.tenantShowOpen(item.tenant)}>{item.tenant.name}</a>
							) : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									<a onClick={() => this.tenantShowOpen(item.tenant)}>{item.tenant.name}</a>
								</Tag>
							);
						}
						else
						{
							content = item.tenant.is_active ? item.tenant.name : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									{item.tenant.name}
								</Tag>
							);
						}
					}

					return content;
				},
			},
			{
				title    : "Criação",
				className: "datetime card-block-width-2",
				render   : (item) => {
					if( listTypeCard )
					{
						return (
							<Fragment>
								<i className="fal fa-plus-circle" style={{marginRight: 5}} />{moment(item.created_at).format("DD/MM/YYYY HH:mm")}
							</Fragment>
						);
					}

					return moment(item.created_at).format("DD/MM/YYYY HH:mm");
				}
			},
			{
				title    : "Exclusão",
				className: "datetime card-block-width-2",
				render   : (item) => {
					if( listTypeCard )
					{
						return (
							<Fragment>
								<i className="fal fa-pen" style={{marginRight: 5}} />{moment(item.deleted_at).format("DD/MM/YYYY HH:mm")}
							</Fragment>
						);
					}

					return moment(item.deleted_at).format("DD/MM/YYYY HH:mm");
				},
			},
			{
				title    : "Ações",
				className: "actions no-ellipsis",
				visible  : this.props.permissions.includes(config.permissionPrefix + ".show"),
				render   : (item) => (
					<Dropdown overlay={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon={<i className="fal fa-ellipsis-v" />} />
					</Dropdown>
				),
			},
		];
	};

	fetchGetAll = (init = false, exportItems = false) => {
		const {pagination, orderByField, orderBySort, search, filters} = this.state;

		if( exportItems )
		{
			this.setState({
				isExporting: true,
			});
		}
		else
		{
			this.setState({
				isLoading: true,
			});
		}

		const data = {
			orderBy: `${orderByField}:${orderBySort}`,
			search : search,
		};

		if( exportItems )
		{
			data.exportItems = true;
		}
		else
		{
			data.page  = init ? 1 : pagination.current;
			data.limit = pagination.pageSize;
		}

		if( filters.tenant_id !== null )
		{
			data.tenant_id = filters.tenant_id;
		}

		if( filters.type_id !== null )
		{
			data.type_id = filters.type_id;
		}

		if( filters.function_id !== null )
		{
			data.function_id = filters.function_id;
		}

		if( filters.deleted_at )
		{
			data.deleted_at = [
				filters.deleted_at[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.deleted_at[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		if( filters.created_at )
		{
			data.created_at = [
				filters.created_at[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.created_at[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		professionalDeletedService.getAll(data)
		.then((response) => {
			if( exportItems )
			{
				this.setState({
					isExporting: false,
				});

				window.open(response.data.file_url, "_blank");
			}
			else
			{
				this.setState(state => ({
					isLoading : false,
					data      : response.data.data,
					pagination: {
						...state.pagination,
						current: response.data.meta.current_page,
						total  : response.data.meta.total,
					},
				}));
			}
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onListTypeChange = (type) => {
		this.props.onChangeListType(type);
	};

	onPaginationChange = (page) => {
		this.setState(state => ({
			pagination: {
				...state.pagination,
				current: page,
			},
		}), () => {
			this.fetchGetAll();
		});
	};

	onOrderChange = (value) => {
		const defaultOrder = config.orders.find(o => `${o.field}:${o.sort}` === value);

		if( !defaultOrder ) return null;

		this.setState(state => ({
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
		}), () => {
			this.fetchGetAll(true);
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.fetchGetAll(true);
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e.hasOwnProperty("type") )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.fetchGetAll(true);
				}
			});
		}
	};

	/**
	 * Show
	 *
	 * @param uuid
	 */
	showOpen = ({uuid}) => {
		this.setState({showModalVisible: true});

		// On open screen
		this.showScreen.onOpen(uuid);
	};

	showOnClose = () => {
		this.setState({showModalVisible: false});
	};

	/**
	 * Filter
	 */
	filtersOpen = () => {
		this.setState({filtersModalVisible: true});

		// On open screen
		this.filtersScreen.onOpen({...this.state.filters});
	};

	filtersOnClose = () => {
		this.setState({filtersModalVisible: false});
	};

	filtersOnComplete = (filters) => {
		this.setState({filtersModalVisible: false});

		this.setState({
			filters: filters,
		}, () => {
			// Fetch all
			this.fetchGetAll(true);
		});
	};

	/**
	 * Show tenant
	 *
	 * @param uuid
	 */
	tenantShowOpen = ({uuid}) => {
		this.setState({tenantShowScreen: true});

		// On open screen
		this.tenantShowScreen.onOpen(uuid);
	};

	tenantShowOnClose = () => {
		this.setState({tenantShowScreen: false});
	};

	/**
	 * Show tenant user function
	 *
	 * @param uuid
	 */
	tenantUserFunctionShowOpen = ({uuid}) => {
		this.setState({tenantUserFunctionShowScreen: true});

		// On open screen
		this.tenantUserFunctionShowScreen.onOpen(uuid);
	};

	tenantUserFunctionShowOnClose = () => {
		this.setState({tenantUserFunctionShowScreen: false});
	};

	/**
	 * Show tenant user type
	 *
	 * @param uuid
	 */
	tenantUserTypeShowOpen = ({uuid}) => {
		this.setState({tenantUserTypeShowScreen: true});

		// On open screen
		this.tenantUserTypeShowScreen.onOpen(uuid);
	};

	tenantUserTypeShowOnClose = () => {
		this.setState({tenantUserTypeShowScreen: false});
	};

	render() {
		return (
			<QueueAnim className="site-content-inner">
				<div className="page-content" key="1">
					<h1 className="page-title">{config.title}</h1>
					<UIPageListing
						onSearch={this.onSearch}
						onSearchChange={this.onSearchChange}
						onPaginationChange={this.onPaginationChange}
						onOrderChange={this.onOrderChange}
						onListTypeChange={this.onListTypeChange}
						onFiltersClick={this.filtersOpen}
						isLoading={this.state.isLoading}
						listType={this.state.listType}
						orderByField={this.state.orderByField}
						orderBySort={this.state.orderBySort}
						orders={config.orders}
						search={this.state.search}
						searchPlaceholder={config.searchPlaceholder}
						data={this.state.data}
						pagination={this.state.pagination}
						columns={this.columns()}
						showFilters={true}
						buttons={[
							{
								visible: this.props.permissions.includes(config.permissionPrefix + ".export"),
								onClick: () => this.fetchGetAll(true, true),
								title  : this.state.isExporting ? "Exportando" : "Exportar",
								icon   : <i className="fal fa-file-export" />,
								loading: this.state.isExporting,
							},
						]}
					/>
				</div>
				<ModalShow
					ref={el => this.showScreen = el}
					visible={this.state.showModalVisible}
					onClose={this.showOnClose}
				/>
				<ModalFilters
					ref={el => this.filtersScreen = el}
					visible={this.state.filtersModalVisible}
					onComplete={this.filtersOnComplete}
					onClose={this.filtersOnClose}
				/>
				<TenantModalShow
					ref={el => this.tenantShowScreen = el}
					visible={this.state.tenantShowScreen}
					onClose={this.tenantShowOnClose}
					external
				/>
				<TenantUserFunctionModalShow
					ref={el => this.tenantUserFunctionShowScreen = el}
					visible={this.state.tenantUserFunctionShowScreen}
					onClose={this.tenantUserFunctionShowOnClose}
					external
				/>
				<TenantUserTypeModalShow
					ref={el => this.tenantUserTypeShowScreen = el}
					visible={this.state.tenantUserTypeShowScreen}
					onClose={this.tenantUserTypeShowOnClose}
					external
				/>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
		listType   : state.general.listType[config.list],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChangeListType: (type) => {
			dispatch(generalActions.changeListType(config.list, type));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
