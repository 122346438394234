import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Modal, Row, Switch, Tabs } from "antd";

import moment from "moment";

import { professionalDeletedService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

const config = {
	externalName: "profissional",
};

class Show extends Component {
	static propTypes = {
		visible : PropTypes.bool.isRequired,
		onClose : PropTypes.func.isRequired,
		external: PropTypes.bool,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			uuid     : 0,
			item     : {},
		};
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid     : uuid,
			item     : {},
		});

		professionalDeletedService.show({uuid})
		.then((response) => {
			let item = response.data.data;

			this.setState({
				isLoading: false,
				item     : item,
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.setState({
			item: {},
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	render() {
		const {visible}               = this.props;
		const {uuid, isLoading, item} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar ${this.props.external ? config.externalName : "registro"} [${uuid}]`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Form.Item label="Empresa">
								{item.tenant?.name ?? '-'}
							</Form.Item>
							<Form.Item label="Função">
								{item.user_function?.name ?? '-'}
							</Form.Item>
							<Form.Item label="Tipo">
								{item.user_type?.name ?? '-'}
							</Form.Item>
							<Form.Item label="Nome">
								{item.name}
							</Form.Item>
							<Form.Item label="E-mail">
								{item.email}
							</Form.Item>
							<Form.Item label="Ativo">
								<Switch disabled checked={item.is_active} />
							</Form.Item>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar()}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Última atualização">
										{moment(item.updated_at).calendar()}
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Show;
