import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, message, Modal, Input, Form, Row, Col } from 'antd'
import QueueAnim from 'rc-queue-anim'

import { generalActions } from './../../redux/actions'

import { certificateService } from './../../redux/services'

const config = {
  title: 'Certificado',
  permissionPrefix: 'settings-certificates',
  list: 'settings-certificates',
}

import { UIUpload } from '../../components'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      data: {},
      // Actions
      editModalVisible: false,
      showModalVisible: false,
      exportModalVisible: false,
      filtersModalVisible: false,
      activeLoadings: [],
      isExporting: false,
      passwordModalVisible: false,
    }
  }

  componentDidMount() {
    // Fetch all
    this.fetchGetAll()
  }

  fetchGetAll = () => {
    this.setState({
      isLoading: true,
    })

    certificateService
      .getAll()
      .then((response) => {
        this.setState(() => ({
          isLoading: false,
          data: response.data.data,
        }))
      })
      .catch((data) => {
        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  resetFields = () => {
    this.form.resetFields()

    this.upload.reset()
  }

  onFinish = (values) => {
    this.setState({
      isSending: true,
    })

    const data = {
      ...values,
      file: this.upload.state.files[0],
    }

    certificateService
      .importCertificate(data)
      .then(() => {
        this.setState({
          isSending: false,
        })

        // Reset fields
        this.resetFields()

        // Success message
        message.success('Registro cadastrado com sucesso.')
      })
      .catch((data) => {
        this.setState({
          isSending: false,
        })

        Modal.error({
          title: 'Ocorreu um erro!',
          content: String(data),
        })
      })
  }

  render() {
    const { isSending } = this.state
    return (
      <QueueAnim
        className="site-content-inner alternative-wrapper"
        style={{ maxWidth: 700 }}
      >
        <div className="page-content fixed-header" key="1">
          <h1 className="page-title">{config.title}</h1>
          <Form
            ref={(el) => (this.form = el)}
            layout="vertical"
            scrollToFirstError
            onFinish={this.onFinish}
          >
            <Row gutter={16}>
              <Col xs={24}>
                <UIUpload
                  ref={(el) => (this.upload = el)}
                  label="Arquivo (pfx):"
                  labelError="Arquivo (pfx):"
                  acceptedFiles={['pfx']}
                />
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name="password"
                  label="Senha"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Campo obrigatório.' },
                    { min: 6, message: 'Deve conter no mínimo 6 caracteres.' },
                  ]}
                >
                  <Input.Password type="password" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  className="btn-save"
                  icon={<i className="far fa-check" />}
                  loading={isSending}
                  disabled={isSending}
                >
                  {isSending ? 'Importando' : 'Importar'}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </QueueAnim>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.userData.permissions,
    listType: state.general.listType[config.list],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeListType: (type) => {
      dispatch(generalActions.changeListType(config.list, type))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
