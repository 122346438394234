import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { resalesContactsService } from "../../../redux/services";

import {
	UIDrawerForm,
	UIUpload,
} from "../../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
		};

		this._axiosCancelToken = null;
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		resalesContactsService.show({ client_uuid: this.props.client_uuid, uuid })
			.then((response) => {
				let item = response.data.data;

				this.setState({
					isLoading: false,
				}, () => {
					// Fill form
					this.fillForm(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			nome: data.nome,
			email: data.email,
			telefone_fixo: data.telefone_fixo,
			telefone_celular: data.telefone_celular,
		});

	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		// const file = this.upload.getFiles();

		// if( file.hasError )
		// {
		// 	Modal.error({
		// 		title  : "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		}

		// uuid
		data.uuid = uuid;

		resalesContactsService.edit(this.props.client_uuid, data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { uuid, isLoading, isSending } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar contato`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Row gutter={16}>
						<Col xs={24} sm={24}>
							<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col sm={24}>
							<Form.Item name="email" label="E-mail" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
						<Form.Item name="telefone_fixo" label="Telefone Fixo" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12}>
						<Form.Item name="telefone_celular" label="Telefone Celular" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
