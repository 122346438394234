import React, { Component, Fragment } from "react";

import moment from "moment";

import { Dropdown, Button, Menu, Modal, Tag } from "antd";

import {
	UIPageListing,
} from "../../../components";

import { resalesUsersService } from "../../../redux/services";

import ModalEdit from "./edit";
import ModalCreate from "./create";

const config = {
	searchPlaceholder: "Buscar Usuários",
	permissionPrefix: "customers",
};

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			listType: "list",
			dataAll: [],
			data: [],
			search: '',
			// Owner item
			ownerItem: {},
			// Actions
			editModalVisible: false,
		};
	}

	init = (ownerItem) => {
		this.setState({
			ownerItem: ownerItem,
		});

		setTimeout(() => {
			this.fetchGetAll()

		}, 1000)
	};
	

	/**
	 * Create
	 */
	createOpen = () => {
		this.setState({ createModalVisible: true });

		// On open screen
		this.createScreen.onOpen();
	};

	createOnClose = () => {
		this.setState({ createModalVisible: false });
	};

	createOnComplete = () => {
		this.setState({ createModalVisible: false });

		// Fetch all
		this.fetchGetAll();
	};

	/**
	 * Edit
	 *
	 * @param uuid
	 */
	editOpen = ({ uuid }) => {
		this.setState({ editModalVisible: true });

		// On open screen
		this.editScreen.onOpen(uuid);
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = ({ uuid }) => {
		Modal.confirm({
			title: "Confirmar exclusão!",
			content: "Tem certeza de que deseja excluir este registro?",
			okText: "Excluir",
			autoFocusButton: null,
			onOk: () => {
				return this.deleteConfirmed(uuid);
			}
		});
	};

	deleteConfirmed = (uuid) => {
		return resalesUsersService.destroy( uuid )
			.then((response) => {
				// Fetch all
				this.fetchGetAll();
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	editOnComplete = () => {
		this.setState({ editModalVisible: false });

		// Fetch all
		this.fetchGetAll();
	};

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			{/* {this.props.permissions.includes(config.permissionPrefix + ".edit") &&  */}
			<Menu.Item key="edit">
				<a onClick={() => this.editOpen(item)}>
					<i className="fal fa-pen" />Editar
				</a>
			</Menu.Item>
			{/* } */}
			{/* {this.props.permissions.includes(config.permissionPrefix + ".delete") &&  */}
			<Menu.Item key="delete" className="divider btn-delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<i className="fal fa-trash" />Excluir
				</a>
			</Menu.Item>
			{/* } */}
		</Menu>
	);

	columns = () => [
		{
			title: "Nome",
			render: (item) => item.name,
		},
		{
			title: "E-mail",
			width: 200,
			render: (item) => item.email,
		},
		{
			title: "Status",
			width: 200,
			render   : (item) => <Tag color={item.is_active ? "#0acf97" : "#f5ad4e" }>{item.is_active ? "Ativo":"Inativo"}</Tag>
		},
		{
			title: "Ações",
			className: "actions no-ellipsis",
			visible: true,
			render: (item) => (
				<Dropdown overlay={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
					<Button icon={<i className="fal fa-ellipsis-v" />} />
				</Dropdown>
			),
		},
	];

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		resalesUsersService.getAll({revenda_id: this.state.ownerItem.uuid})
			.then((response) => {
				
				this.setState(state => ({
					isLoading: false,
					data: response.data.data,
					pagination: {
						...state.pagination,
						current: response.data.meta.current_page,
						total: response.data.meta.total,
					},
				}));
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	searchItems = () => {
		const { search } = this.state;
		const newData = [...this.state.dataAll];

		this.setState({
			data: search ? newData.filter((item) => item.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1 || item.nome.toLowerCase().indexOf(search.toLowerCase()) !== -1) : newData,
		});
	};

	editOnClose = () => {
		this.setState({ editModalVisible: false });
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.searchItems();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if (!e.hasOwnProperty("type")) {
			const { search } = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if (search) {
					this.searchItems();
				}
			});
		}
	};


	render() {
		return (
			<Fragment>
				<UIPageListing
					onSearch={this.onSearch}
					onSearchChange={this.onSearchChange}
					listType={this.state.listType}
					search={this.state.search}
					searchPlaceholder={config.searchPlaceholder}
					showListTypeChange={false}
					enablePagination={false}
					enableSearch={false}
					data={this.state.data}
					columns={this.columns()}
					buttons={[
						{
							visible: true,
							// visible: this.props.permissions.includes(config.permissionPrefix + ".create"),
							onClick: this.createOpen,
							title: "Incluir",
							icon: <i className="far fa-plus" />,
						},
					]}
				/>
				<ModalEdit
					client_uuid={this.state.ownerItem.uuid}
					ref={el => this.editScreen = el}
					visible={this.state.editModalVisible}
					onComplete={this.editOnComplete}
					onClose={this.editOnClose}
				/>
				<ModalCreate
					client_uuid={this.state.ownerItem.uuid}
					ref={el => this.createScreen = el}
					visible={this.state.createModalVisible}
					onComplete={this.createOnComplete}
					onClose={this.createOnClose}
				/>
			</Fragment>
		)
	}
}

export default Index;
