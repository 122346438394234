import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "../../../config/general";

import { resalesContactsService } from "../../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	UIDrawerForm,
	UILabelHelp,
} from "../../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
		client_uuid: PropTypes.any,
		revenda_id: PropTypes.any,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
		};
	}

	onOpen = () => {
		this.setState({
			isLoading: false,
		});

		setTimeout(() => {
			this.nome && this.nome.focus()

		}, 100)

	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = {
			...values
		}

		data.revenda_id = this.props.revenda_id;
		resalesContactsService.create(this.props.client_uuid, data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir novo contato">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Row gutter={16}>
						<Col xs={24} sm={24}>
							<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input ref={el => this.nome = el }/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col sm={24}>
							<Form.Item name="email" label="E-mail" rules={[{ required: true, message: "Campo obrigatório." }]}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
						<Form.Item name="telefone_fixo" label="Telefone Fixo" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12}>
						<Form.Item name="telefone_celular" label="Telefone Celular" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
