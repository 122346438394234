import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Input, InputNumber, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { anamneseService } from "./../../redux/services";

import {
	UIDrawerForm,
	UILabelHelp,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			uuid     : 0,
			isLoading: true,
			isSending: false,
			type     : null,
		};
	}

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid     : uuid,
		});

		anamneseService.show({uuid})
		.then((response) => {
			const item = response.data.data;

			this.setState({
				isLoading: false,
				type     : item.type,
			}, () => {
				// Fill form
				this.fillForm(item);
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			name     : data.name,
			type     : data.type,
			values   : data.values ? data.values.join('\n') : '',
			order    : data.order,
			required : data.required,
			is_active: data.is_active,
		});
	};

	onClose = () => {
		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const {uuid} = this.state;

		const data = {...values};

		// uuid
		data.uuid = uuid;

		anamneseService.edit(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Success message
			message.success("Registro atualizado com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const {visible} = this.props;

		const {uuid, isLoading, isSending, type} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar registro [${uuid}]`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Form.Item name="name" label="Título" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Input maxLength={191} />
							</Form.Item>
							<Form.Item name="type" label="Tipo de campo" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Select
									optionFilterProp="children"
									filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
									allowClear
									placeholder="Selecione o tipo"
									showSearch
									onChange={(value) => this.setState({type: value})}>
									<Select.Option value="select">Escolha única</Select.Option>
									<Select.Option value="select_multiple">Múltipla escolha</Select.Option>
									<Select.Option value="text">Texto livre</Select.Option>
								</Select>
							</Form.Item>
							{(type === 'select' || type === 'select_multiple') && (
								<Form.Item name="values" label={<UILabelHelp title="Opções" content="Coloque uma opção por linha." />}>
									<Input.TextArea autoSize={{minRows: 3}} />
								</Form.Item>
							)}
							<Form.Item name="order" label="Ordem" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<InputNumber min={0} style={{width: '100%', maxWidth: 150}} />
							</Form.Item>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="required" label="Obrigatório" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="is_active" label="Ativo" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
