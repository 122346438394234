import { api } from "./../../config/api";
import { appendToFormData } from "./../../helpers/form";

const basePath = "terms-of-use";

/**
 * Show
 *
 * @param type
 * @param cancelToken
 *
 * @returns {Promise<T>}
 */
export const show = (type, cancelToken) => {
	return api.get(`${basePath}/${type}`, {cancelToken});
};

/**
 * Edit
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const edit = (options) => {
	const formData = new FormData();

	for( let key in options )
	{
		if( options.hasOwnProperty(key) )
		{
			appendToFormData(formData, key, options[key]);
		}
	}

	return api.post(`${basePath}/${options.type}`, formData);
};
