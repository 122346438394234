import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

import { examService } from "./../../../redux/services";

import {
	UIPageListing,
} from "./../../../components";

const config = {
	searchPlaceholder: "Buscar arquivo",
	permissionPrefix : "exams",
};

class Index extends Component {
	constructor(props) {
		super(props);

		this.state = {
			listType     : "list",
			dataAll      : [],
			data         : [],
			search       : '',
			isDownloading: false,
			// Owner item
			ownerItem: {},
			// Images
			imagePreviewVisible: false,
			imagePreviewImage  : "",
		};
	}

	init = (ownerItem) => {
		this.setState({
			ownerItem: ownerItem,
			dataAll  : ownerItem.files,
			data     : ownerItem.files,
		});
	};

	columns = () => [
		{
			title    : "ID",
			className: "id",
			render   : (item) => <span title={item.uuid}>{item.uuid}</span>,
		},
		{
			className: "no-padding-horizontal no-ellipsis text-center",
			width    : 70,
			render   : (item) => {
				if( item.type === 'image' )
				{
					return (
						<a onClick={() => this.onImagePreview(item.file)}>
							<img style={{maxHeight: 50}} src={item.file_sizes.admin_listing} />
						</a>
					);
				}

				return (
					<a target="_blank" href={item.file}>
						<i className="fal fa-download" style={{fontSize: 24}} />
					</a>
				);
			}
		},
		{
			title : "Nome",
			render: (item) => item.name,
		},
	];

	searchItems = () => {
		const {search} = this.state;
		const newData  = [...this.state.dataAll];

		this.setState({
			data: search ? newData.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : newData,
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.searchItems();
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e.hasOwnProperty("type") )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.searchItems();
				}
			});
		}
	};

	downloadFiles = (init = false, exportItems = false) => {
		this.setState({
			isDownloading: true,
		});

		examService.download({
			uuid: this.state.ownerItem.uuid,
		})
		.then((response) => {
			this.setState({
				isDownloading: false,
			});

			window.open(response.data.file_url, "_blank");
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	/**
	 * Image preview
	 */
	onImagePreviewClose = () => this.setState({imagePreviewVisible: false});

	onImagePreview = (url) => {
		this.setState({
			imagePreviewImage  : url,
			imagePreviewVisible: true,
		});
	};

	render() {
		return (
			<Fragment>
				<UIPageListing
					onSearch={this.onSearch}
					onSearchChange={this.onSearchChange}
					listType={this.state.listType}
					search={this.state.search}
					searchPlaceholder={config.searchPlaceholder}
					showListTypeChange={false}
					enablePagination={false}
					data={this.state.data}
					columns={this.columns()}
					buttons={[
						{
							visible: this.props.permissions.includes(config.permissionPrefix + ".list") || this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit"),
							onClick: this.downloadFiles,
							title  : this.state.isDownloading ? "Baixando" : "Baixar arquivos",
							icon   : <i className="fal fa-file-export" />,
							loading: this.state.isDownloading,
						},
					]}
				/>
				<Modal wrapClassName="modal-image" visible={this.state.imagePreviewVisible} centered footer={null} destroyOnClose={true} onCancel={this.onImagePreviewClose}>
					<img src={this.state.imagePreviewImage} />
				</Modal>
			</Fragment>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
		listType   : state.general.listType[config.list],
	};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(Index);
