import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Dropdown, Menu, Modal, Spin, Tag } from "antd";
import QueueAnim from "rc-queue-anim";

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { generalActions } from "./../../redux/actions";

import { examService } from "./../../redux/services";

import {
	UIPageListing,
} from "./../../components";

import ModalShow from "./show";
import ModalFilters from "./filters";
import TenantModalShow from "./../Tenants/show";
import CustomerModalShow from "./../Customers/show";

const config = {
	title                    : "Exames",
	permissionPrefix         : "exams",
	tenantsPermissionPrefix  : "tenants",
	customersPermissionPrefix: "customers",
	list                     : "exams",
	searchPlaceholder        : "Buscar por nome ou cpf do paciente",
	orders                   : [
		{
			label  : "Exames recentes",
			field  : "date",
			sort   : "desc",
			default: true,
		},
		{
			label: "Exames antigos",
			field: "date",
			sort : "asc",
		},
		/*
		{
			label  : "Mais recentes",
			field  : "created_at",
			sort   : "desc",
			default: true,
		},
		{
			label: "Mais antigos",
			field: "created_at",
			sort : "asc",
		},
		*/
		{
			label: "Nome A|Z",
			field: "name",
			sort : "asc",
		},
		{
			label: "Nome Z|A",
			field: "name",
			sort : "desc",
		},
	],
};

class Index extends Component {
	constructor(props) {
		super(props);

		const defaultOrder = config.orders.find(o => o.default);

		this.state = {
			isLoading   : false,
			listType    : "list",
			data        : [],
			pagination  : {
				current : 1,
				pageSize: 20,
				total   : 0,
			},
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
			search      : "",
			// Actions
			showModalVisible   : false,
			exportModalVisible : false,
			filtersModalVisible: false,
			activeLoadings     : [],
			isExporting        : false,
			// Filters
			filters     : {
				tenant_id  : null,
				customer_id: null,
				is_active  : null,
				date       : null,
				created_at : null,
			},
			// Relations
			tenantShowScreen  : false,
			customerShowScreen: false,
		};
	}

	static getDerivedStateFromProps(props, state) {
		if( props.listType && state.listType !== props.listType )
		{
			return {
				listType: props.listType
			};
		}

		return null;
	}

	componentDidMount() {
		// Fetch all
		this.fetchGetAll(true);

		this._cancelToken = null;
	};

	componentWillUnmount() {
		this._cancelToken && this._cancelToken.cancel("Landing Component got unmounted");
	}

	componentDidUpdate(prevProps) {
		if( prevProps.match.params.type !== this.props.match.params.type )
		{
			// Fetch all
			this.fetchGetAll(true);
		}
	}

	menuItem = (item) => (
		<Menu className="actions-dropdown-menu">
			{this.props.permissions.includes(config.permissionPrefix + ".show") && <Menu.Item key="show">
				<a onClick={() => this.showOpen(item)}>
					<i className="fal fa-file" />Visualizar
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".edit") && <Menu.Item key="activate/deactivate" className="divider">
				<a onClick={() => this.activateDeactivate(item, !item.is_active)}>
					{item.is_active ? <i className="fal fa-eye-slash" /> : <i className="fal fa-eye" />}{item.is_active ? "Desativar" : "Ativar"}
				</a>
			</Menu.Item>}
			{this.props.permissions.includes(config.permissionPrefix + ".delete") && <Menu.Item key="delete" className="divider btn-delete">
				<a onClick={() => this.deleteConfirm(item)}>
					<i className="fal fa-trash" />Excluir
				</a>
			</Menu.Item>}
		</Menu>
	);

	columns = () => {
		const listTypeCard = this.state.listType === "card";

		const columns = [
			{
				title    : "ID",
				className: "id",
				visible  : !listTypeCard,
				render   : (item) => <span title={item.uuid}>{item.uuid}</span>,
			},
			{
				title : "Paciente",
				render: (item) => {
					let content = "";

					if( item.customer )
					{
						if( this.props.permissions.includes(config.customersPermissionPrefix + ".show") )
						{
							content = item.customer.is_active ? (
								<a onClick={() => this.customerShowOpen(item.customer)}>{item.customer.name}</a>
							) : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									<a onClick={() => this.customerShowOpen(item.customer)}>{item.customer.name}</a>
								</Tag>
							);
						}
						else
						{
							content = item.customer.is_active ? item.customer.name : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									{item.customer.name}
								</Tag>
							);
						}
					}

					return content;
				},
			},
			{
				title : "Empresa",
				width : 220,
				render: (item) => {
					let content = "";

					if( item.tenant )
					{
						if( this.props.permissions.includes(config.tenantsPermissionPrefix + ".show") )
						{
							content = item.tenant.is_active ? (
								<a onClick={() => this.tenantShowOpen(item.tenant)}>{item.tenant.name}</a>
							) : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									<a onClick={() => this.tenantShowOpen(item.tenant)}>{item.tenant.name}</a>
								</Tag>
							);
						}
						else
						{
							content = item.tenant.is_active ? item.tenant.name : (
								<Tag style={{margin: "0 0 5px"}} color="#777" title="Inativo">
									{item.tenant.name}
								</Tag>
							);
						}
					}

					return content;
				},
			},
			{
				title : "Nome do exame",
				render: (item) => item.name,
			},
			{
				key      : "category",
				title    : "Categoria do exame",
				width    : 180,
				className: "no-ellipsis",
				render   : (item) => item.category?.name ?? '-',
			},
			{
				title : "Data do exame",
				width : 150,
				render: (item) => moment(item.date).format("DD/MM/YYYY HH:mm"),
			},
			{
				title    : "Criação",
				className: "datetime card-block-width-2",
				render   : (item) => {
					if( listTypeCard )
					{
						return (
							<Fragment>
								<i className="fal fa-plus-circle" style={{marginRight: 5}} />{moment(item.created_at).format("DD/MM/YYYY HH:mm")}
							</Fragment>
						);
					}

					return moment(item.created_at).format("DD/MM/YYYY HH:mm");
				}
			},
			{
				title    : "Últ. modificação",
				className: "datetime card-block-width-2",
				render   : (item) => {
					if( listTypeCard )
					{
						return (
							<Fragment>
								<i className="fal fa-pen" style={{marginRight: 5}} />{moment(item.updated_at).format("DD/MM/YYYY HH:mm")}
							</Fragment>
						);
					}

					return moment(item.updated_at).format("DD/MM/YYYY HH:mm");
				},
			},
			{
				title    : "Ativo",
				className: "active no-ellipsis",
				render   : (item) => this.state.activeLoadings.indexOf(item.uuid) !== -1 ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : <Tag color={item.is_active ? "#0acf97" : "#fa5c7c"}>{item.is_active ? "Ativo" : "Inativo"}</Tag>
			},
			{
				title    : "Ações",
				className: "actions no-ellipsis",
				visible  : this.props.permissions.includes(config.permissionPrefix + ".show") || this.props.permissions.includes(config.permissionPrefix + ".edit") || this.props.permissions.includes(config.permissionPrefix + ".delete"),
				render   : (item) => (
					<Dropdown overlay={this.menuItem(item)} className="actions-dropdown" placement="bottomRight" trigger={["click"]}>
						<Button icon={<i className="fal fa-ellipsis-v" />} />
					</Dropdown>
				),
			},
		];

		if( this.props.match.params?.type === 'dental' )
		{
			return columns.filter((column) => column.key !== 'category');
		}

		return columns;
	};

	fetchGetAll = (init = false, exportItems = false) => {
		if( this._cancelToken )
		{
			this._cancelToken.cancel("Only one request allowed at a time.");
		}

		this._cancelToken = axios.CancelToken.source();

		const {pagination, orderByField, orderBySort, search, filters} = this.state;

		if( exportItems )
		{
			this.setState({
				isExporting: true,
			});
		}
		else
		{
			this.setState({
				isLoading: true,
			});
		}

		const {type} = this.props.match.params;

		const data = {
			orderBy: `${orderByField}:${orderBySort}`,
			search : search,
			type   : type,
		};

		if( exportItems )
		{
			data.exportItems = true;
		}
		else
		{
			data.page  = init ? 1 : pagination.current;
			data.limit = pagination.pageSize;
		}

		if( filters.tenant_id !== null )
		{
			data.tenant_id = filters.tenant_id;
		}

		if( filters.customer_id !== null )
		{
			data.customer_id = filters.customer_id;
		}

		if( filters.is_active !== null )
		{
			data.is_active = filters.is_active;
		}

		if( filters.date )
		{
			data.date = [
				filters.date[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.date[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		if( filters.created_at )
		{
			data.created_at = [
				filters.created_at[0].clone().startOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
				filters.created_at[1].clone().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ")
			];
		}

		examService.getAll(data, this._cancelToken.token)
		.then((response) => {
			if( exportItems )
			{
				this.setState({
					isExporting: false,
				});

				window.open(response.data.file_url, "_blank");
			}
			else
			{
				this.setState(state => ({
					isLoading : false,
					data      : response.data.data,
					pagination: {
						...state.pagination,
						current: response.data.meta.current_page,
						total  : response.data.meta.total,
					},
				}));
			}
		})
		.catch((data) => {
			if( data?.error_type === API_ERRO_TYPE_CANCEL ) return null;

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onListTypeChange = (type) => {
		this.props.onChangeListType(type);
	};

	onPaginationChange = (page) => {
		this.setState(state => ({
			pagination: {
				...state.pagination,
				current: page,
			},
		}), () => {
			this.fetchGetAll();
		});
	};

	onOrderChange = (value) => {
		const defaultOrder = config.orders.find(o => `${o.field}:${o.sort}` === value);

		if( !defaultOrder ) return null;

		this.setState(state => ({
			orderByLabel: defaultOrder.label,
			orderByField: defaultOrder.field,
			orderBySort : defaultOrder.sort,
		}), () => {
			this.fetchGetAll(true);
		});
	};

	onSearch = (value) => {
		this.setState({
			search: value,
		}, () => {
			this.fetchGetAll(true);
		});
	};

	onSearchChange = (e) => {
		// If it does not have type then it's cleaning
		if( !e.hasOwnProperty("type") )
		{
			const {search} = this.state;

			this.setState({
				search: e.target.value,
			}, () => {
				if( search )
				{
					this.fetchGetAll(true);
				}
			});
		}
	};

	/**
	 * Show
	 *
	 * @param uuid
	 */
	showOpen = ({uuid}) => {
		this.setState({showModalVisible: true});

		// On open screen
		this.showScreen.onOpen(uuid);
	};

	showOnClose = () => {
		this.setState({showModalVisible: false});
	};

	/**
	 * Delete
	 *
	 * @param uuid
	 */
	deleteConfirm = ({uuid}) => {
		Modal.confirm({
			title          : "Confirmar exclusão!",
			content        : "Tem certeza de que deseja excluir este registro?",
			okText         : "Excluir",
			autoFocusButton: null,
			onOk           : () => {
				return this.deleteConfirmed(uuid);
			}
		});
	};

	deleteConfirmed = (uuid) => {
		return examService.destroy({uuid})
		.then((response) => {
			// Fetch all
			this.fetchGetAll();
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	/**
	 * Filter
	 */
	filtersOpen = () => {
		this.setState({filtersModalVisible: true});

		// On open screen
		this.filtersScreen.onOpen({...this.state.filters});
	};

	filtersOnClose = () => {
		this.setState({filtersModalVisible: false});
	};

	filtersOnComplete = (filters) => {
		this.setState({filtersModalVisible: false});

		this.setState({
			filters: filters,
		}, () => {
			// Fetch all
			this.fetchGetAll(true);
		});
	};

	/**
	 * Show tenant
	 *
	 * @param uuid
	 */
	tenantShowOpen = ({uuid}) => {
		this.setState({tenantShowScreen: true});

		// On open screen
		this.tenantShowScreen.onOpen(uuid);
	};

	tenantShowOnClose = () => {
		this.setState({tenantShowScreen: false});
	};

	/**
	 * Show customer
	 *
	 * @param uuid
	 */
	customerShowOpen = ({uuid}) => {
		this.setState({customerShowScreen: true});

		// On open screen
		this.customerShowScreen.onOpen(uuid);
	};

	customerShowOnClose = () => {
		this.setState({customerShowScreen: false});
	};

	/**
	 * Active/Desactive
	 *
	 * @param {number} uuid
	 * @param {boolean} activate
	 */
	activateDeactivate = ({uuid}, activate) => {
		const {activeLoadings} = this.state;

		if( activeLoadings.indexOf(uuid) === -1 )
		{
			activeLoadings.push(uuid);
		}

		this.setState({
			activeLoadings: activeLoadings,
		});

		examService.edit({uuid, is_active: activate})
		.then((response) => {
			const newData = [...this.state.data];
			const index   = newData.findIndex(item => uuid === item.uuid);

			if( index !== -1 )
			{
				const item = newData[index];

				newData.splice(index, 1, {
					...item,
					is_active: response.data.data.is_active,
				});

				this.setState({
					data: newData,
				});
			}
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		}).finally(() => {
			const {activeLoadings} = this.state;
			const index            = activeLoadings.indexOf(uuid);

			if( index !== -1 )
			{
				activeLoadings.splice(index, 1);

				this.setState({
					activeLoadings: activeLoadings,
				});
			}
		});
	};

	render() {
		return (
			<QueueAnim className="site-content-inner">
				<div className="page-content" key="1">
					<h1 className="page-title">{config.title}</h1>
					<UIPageListing
						onSearch={this.onSearch}
						onSearchChange={this.onSearchChange}
						onPaginationChange={this.onPaginationChange}
						onOrderChange={this.onOrderChange}
						onListTypeChange={this.onListTypeChange}
						onFiltersClick={this.filtersOpen}
						isLoading={this.state.isLoading}
						listType={this.state.listType}
						orderByField={this.state.orderByField}
						orderBySort={this.state.orderBySort}
						orders={config.orders}
						search={this.state.search}
						searchPlaceholder={config.searchPlaceholder}
						data={this.state.data}
						pagination={this.state.pagination}
						columns={this.columns()}
						showFilters={true}
						buttons={[
							{
								visible: this.props.permissions.includes(config.permissionPrefix + ".export"),
								onClick: () => this.fetchGetAll(true, true),
								title  : this.state.isExporting ? "Exportando" : "Exportar",
								icon   : <i className="fal fa-file-export" />,
								loading: this.state.isExporting,
							},
						]}
					/>
				</div>
				<ModalShow
					ref={el => this.showScreen = el}
					visible={this.state.showModalVisible}
					onClose={this.showOnClose}
				/>
				<ModalFilters
					ref={el => this.filtersScreen = el}
					visible={this.state.filtersModalVisible}
					onComplete={this.filtersOnComplete}
					onClose={this.filtersOnClose}
				/>
				<TenantModalShow
					ref={el => this.tenantShowScreen = el}
					visible={this.state.tenantShowScreen}
					onClose={this.tenantShowOnClose}
					external
				/>
				<CustomerModalShow
					ref={el => this.customerShowScreen = el}
					visible={this.state.customerShowScreen}
					onClose={this.customerShowOnClose}
					external
				/>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
		listType   : state.general.listType[config.list],
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChangeListType: (type) => {
			dispatch(generalActions.changeListType(config.list, type));
		}
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
