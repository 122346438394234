import Account from "./../screens/Account";
import AccountPassword from "./../screens/AccountPassword";
import AboutApp from "./../screens/AboutApp";
import Anamnese from "./../screens/Anamnese";
import Certificate from "../screens/Certificate";
import Customers from "./../screens/Customers";
import Exams from "./../screens/Exams";
import ExamsCategories from "./../screens/ExamsCategories";
import Faq from "./../screens/Faq";
import Home from "./../screens/Home";
import Login from "./../screens/Login";
import Logs from "./../screens/Logs";
import PrivacyPolicy from "./../screens/PrivacyPolicy";
import Professionals from "./../screens/Professionals";
import ProfessionalsDeleted from "./../screens/ProfessionalsDeleted";
import PushCity from "./../screens/PushCity";
import PushGeneral from "./../screens/PushGeneral";
import PushState from "./../screens/PushState";
import PushUser from "./../screens/PushUser";
import RecoveryPassword from "./../screens/RecoveryPassword";
import RolesAndPermissions from "./../screens/RolesAndPermissions";
import SettingsGeneral from "./../screens/SettingsGeneral";
import SettingsNotifications from "./../screens/SettingsNotifications";
import SystemLog from "./../screens/SystemLog";
import Tenants from "./../screens/Tenants";
import TenantsDeleted from "./../screens/TenantsDeleted";
import TenantUserFunctions from "./../screens/TenantUserFunctions";
import TenantUserTypes from "./../screens/TenantUserTypes";
import TermOfUse from "./../screens/TermOfUse";
import Users from "./../screens/Users";
import Vaccines from "./../screens/Vaccines";
import Equipments from "./../screens/Equipments";
import Resales from "./../screens/Resales";

// -----------------------------------------------------------------------------
// Routes
// -----------------------------------------------------------------------------
/**
 * Private routes for registered user access
 *
 * @type {Array}
 */
export const PRIVATE_ROUTES = [
	// Home
	{
		path     : "/",
		component: Home,
		exact    : true,
	},
	// Account
	{
		path     : "/account",
		component: Account,
		exact    : true,
	},
	{
		path     : "/account/password",
		component: AccountPassword,
		exact    : true,
	},
	// Administrator
	{
		path     : "/administrator/anamnese",
		component: Anamnese,
		exact    : true,
	},
	{
		path     : "/administrator/exams-categories",
		component: ExamsCategories,
		exact    : true,
	},
	{
		path     : "/administrator/logs",
		component: Logs,
		exact    : true,
	},
	{
		path     : "/administrator/roles-and-permissions",
		component: RolesAndPermissions,
		exact    : true,
	},
	{
		path     : "/administrator/system-log",
		component: SystemLog,
		exact    : true,
	},
	{
		path     : "/administrator/tenant-user-functions",
		component: TenantUserFunctions,
		exact    : true,
	},
	{
		path     : "/administrator/tenant-user-types",
		component: TenantUserTypes,
		exact    : true,
	},
	{
		path     : "/administrator/users",
		component: Users,
		exact    : true,
	},
	{
		path     : "/settings-certificates/certificate",
		component: Certificate,
		exact    : true,
	},
	// Institutional
	{
		path     : "/institutional/about-app",
		component: AboutApp,
		exact    : true,
	},
	{
		path     : "/institutional/faq/:type(funcionario|empresa)",
		component: Faq,
		exact    : true,
	},
	{
		path     : "/institutional/privacy-policy/:type(funcionario|empresa)",
		component: PrivacyPolicy,
		exact    : true,
	},
	{
		path     : "/institutional/terms-of-use/:type(funcionario|empresa)",
		component: TermOfUse,
		exact    : true,
	},
	// Lists
	{
		path     : "/list/customers",
		component: Customers,
		exact    : true,
	},
	{
		path     : "/list/resales",
		component: Resales,
		exact    : true,
	},
	{
		path     : "/list/equipments",
		component: Equipments,
		exact    : true,
	},
	{
		path     : "/list/professionals",
		component: Professionals,
		exact    : true,
	},
	{
		path     : "/list/tenants",
		component: Tenants,
		exact    : true,
	},
	// Lists deleted
	{
		path     : "/list-deleted/professionals",
		component: ProfessionalsDeleted,
		exact    : true,
	},
	{
		path     : "/list-deleted/tenants",
		component: TenantsDeleted,
		exact    : true,
	},
	// Exams
	{
		path     : "/exams/:type(medical|dental)",
		component: Exams,
		exact    : true,
	},
	// Push
	{
		path     : "/push/city",
		component: PushCity,
		exact    : true,
	},
	{
		path     : "/push/general",
		component: PushGeneral,
		exact    : true,
	},
	{
		path     : "/push/user",
		component: PushUser,
		exact    : true,
	},
	{
		path     : "/push/state",
		component: PushState,
		exact    : true,
	},
	// Settings
	{
		path     : "/settings/general",
		component: SettingsGeneral,
		exact    : true,
	},
	{
		path     : "/settings/notifications",
		component: SettingsNotifications,
		exact    : true,
	},
	// Vaccines
	{
		path     : "/vaccines",
		component: Vaccines,
		exact    : true,
	},
];

/**
 * Session routes that if logged in need to be redirected to the dashboard
 *
 * @type {Array}
 */
export const SESSION_ROUTES = [
	// Login
	{
		path     : "/login",
		component: Login,
		exact    : true,
	},
	// Recovery password
	{
		path     : "/recovery-password",
		component: RecoveryPassword,
		exact    : true,
	},
];
