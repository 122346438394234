import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card, Col, Modal, Row, Spin } from "antd";
import QueueAnim from "rc-queue-anim";

import moment from "moment";

import { dashboardService } from "./../../redux/services";

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			assinaturas_a_vencer_trinta_dias: 0,
			total_clientes: 0,
			total_revendas: 0,
			total_funcionarios: 0,
			revendas_ativas_trinta_dias: 0,
		};
	}

	componentDidMount() {
		// Fetch all
		this.fetchGetAll();
	};

	fetchGetAll = () => {
		this.setState({
			isLoading: true,
		});

		dashboardService.getAll()
			.then((response) => {
				const data = response.data.data;
				console.log("sdfds: ", response);
				this.setState(state => ({
					isLoading: false,
					assinaturas_a_vencer_trinta_dias: data.assinaturas_a_vencer_trinta_dias?.length ?? 0,
					total_clientes: data.total_clientes ?? 0,
					total_revendas: data.total_revendas ?? 0,
					total_funcionarios: parseInt(data.total_funcionarios) ?? 0,
					revendas_ativas_trinta_dias: data.revendas_ativas_trinta_dias?.length ?? 0,
				}));
			})
			.catch((data) => {
				this.setState({
					isLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});


	};

	greeting = () => {
		const hour = moment().hour();
		let day = "Bom dia";

		if (hour >= 19) {
			day = "Boa noite";
		}
		else if (hour >= 12) {
			day = "Boa tarde";
		}

		return `Olá ${this.props.user.name}, ${day}!`;
	};

	render() {
		const {
			isLoading,
			assinaturas_a_vencer_trinta_dias,
			total_clientes,
			total_revendas,
			total_funcionarios,
			revendas_ativas_trinta_dias,
		} = this.state;

		// TODO definir corretamente as urls dos cards

		return (
			<QueueAnim className="site-content-inner page-home alternative-wrapper">
				<div className="page-content" key="1">
					<h1 className="page-title">{this.greeting()}</h1>
					{isLoading ? (
						<div className="text-center">
							<Spin indicator={<i className="fad fa-spinner-third fa-spin fa-3x" />} />
						</div>
					) : (
						<Fragment>
							<div className="cards">
								<Row gutter={16}>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Assinaturas a vencer nos próximos 30 dias</h3>
											<div className="value">{assinaturas_a_vencer_trinta_dias}</div>
											<i className="fad fa-calendar-times" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Total de clientes </h3>
											<div className="value">{total_clientes}</div>
											<i className="fad fa-user" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Total de revendas</h3>
											<div className="value">{total_revendas}</div>
											<i className="fad fa-building" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Total de funcionários</h3>
											<div className="value">{total_funcionarios}</div>
											<i className="fad fa-poll-people" />
										</Card>
									</Col>
									<Col xs={24} sm={8} lg={6} xxl={4}>
										<Card data-has-link={true} onClick={() => null/*this.props.permissions.includes("customers.list") && this.props.history.push('/list/customers')*/}>
											<h3>Revendas ativadas nos últimos 30 dias</h3>
											<div className="value">{revendas_ativas_trinta_dias}</div>
											<i className="fad fa-building" />
										</Card>
									</Col>
								</Row>
							</div>
						</Fragment>
					)}
				</div>
			</QueueAnim>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		user: state.auth.userData,
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(Home);
