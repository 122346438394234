import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Alert, Col, Form, Input, Modal, Row, Switch } from "antd";

import { customerService } from "./../../redux/services";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Password extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isSending     : false,
			item          : null,
			passwordRandom: true,
		};
	}

	onOpen = (item) => {
		this.setState({
			item: item,
		});
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			passwordRandom: true
		});
	};

	onClose = () => {
		if( this.state.isSending )
		{
			return false;
		}

		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onSubmit = () => {
		this.form.validateFields().then((values) => {
			this.setState({
				isSending: true
			});

			const {item} = this.state;

			const data = {
				uuid: item.uuid,
				...values,
			};

			customerService.resendPassword(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title  : "Ocorreu um erro!",
					content: data.error_message,
				});
			});
		}).catch((data) => {
			//
		});
	};

	render() {
		const {visible}                   = this.props;
		const {isSending, passwordRandom} = this.state;

		return (
			<Modal
				visible={visible}
				title="Recriar senha"
				destroyOnClose={true}
				maskClosable={false}
				width={450}
				okText={isSending ? "Enviando" : "Enviar"}
				cancelButtonProps={{disabled: isSending}}
				onCancel={this.onClose}
				onOk={this.onSubmit}
				confirmLoading={isSending}>
				<Form
					ref={el => this.form = el}
					layout="vertical"
					initialValues={{
						password_random: passwordRandom,
					}}>
					<Alert message="O usuário irá receber a nova senha por e-mail." type="info" showIcon icon={<i className="fas fa-info-circle" />} style={{marginBottom: 20}} />
					<Form.Item name="password_random" label="Gerar senha aleatória" valuePropName="checked">
						<Switch onChange={(checked) => this.setState({passwordRandom: checked})} />
					</Form.Item>
					{!passwordRandom && (
						<Row gutter={16}>
							<Col xs={24} sm={12}>
								<Form.Item name="password" label="Senha" hasFeedback rules={[{required: true, message: "Campo obrigatório."}, {min: 6, message: "Deve conter no mínimo 6 caracteres."}]}>
									<Input type="password" />
								</Form.Item>
							</Col>
							<Col xs={24} sm={12}>
								<Form.Item name="password_confirmation" label="Confirmar senha" hasFeedback rules={[
									{required: true, message: "Campo obrigatório."},
									({getFieldValue}) => ({
										validator(rule, value) {
											if( !value || getFieldValue('password') === value )
											{
												return Promise.resolve();
											}

											return Promise.reject("Deve conter o mesmo valor de Senha.");
										},
									}),
								]}>
									<Input type="password" />
								</Form.Item>
							</Col>
						</Row>
					)}
				</Form>
			</Modal>
		)
	}
}

export default Password;
