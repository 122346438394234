import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Divider, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { tenantService, webserviceService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading       : true,
			isSending       : false,
			uuid            : 0,
			isSendingZipcode: false,
			cities          : [],
			citiesIsLoading : false,
		};

		this._axiosCancelToken = null;
	}

	fetchCities = (value) => {
		if( this._axiosCancelToken )
		{
			this._axiosCancelToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				citiesIsLoading: false,
				cities         : [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search     : value,
			cancelToken: this._axiosCancelToken.token,
		})
		.then((response) => {
			this.setState({
				citiesIsLoading: false,
				cities         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				citiesIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid     : uuid,
		});

		tenantService.show({uuid})
		.then((response) => {
			let item = response.data.data;

			let cities = [];

			if( item.city )
			{
				cities.push({
					uuid      : item.city.uuid,
					name      : item.city.name,
					full_name : item.city.full_name,
					state_id  : item.city.state.uuid,
					state_abbr: item.city.state.abbr,
					state_name: item.city.state.name,
				});
			}

			this.setState({
				isLoading: false,
				cities   : cities,
			}, () => {
				// Fill form
				this.fillForm(item);
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			city_id             : data.city?.uuid ?? '',
			name                : data.name,
			email               : data.email,
			document            : data.document,
			phone               : data.phone,
			zipcode             : data.zipcode,
			street              : data.street,
			number              : data.number,
			complement          : data.complement,
			district            : data.district,
			responsible         : data.responsible,
			responsible_email   : data.responsible_email,
			responsible_document: data.responsible_document,
			responsible_phone   : data.responsible_phone,
			is_active           : data.is_active,
		});
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const {uuid} = this.state;

		const data = {
			...values,
			document_type            : 'CNPJ',
			responsible_document_type: 'CPF',
		};

		// uuid
		data.uuid = uuid;

		tenantService.edit(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success("Registro atualizado com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	zipcodeKeyUp = (e) => {
		// Ctrl + A, Ctrl + C, 9 tab, 16 shift, 18 alt, 17 Ctrl, 37 left, 39 right, 38 up, 40 down
		if( (e.ctrlKey && e.keyCode === 65) || (e.ctrlKey && e.keyCode === 67) || e.keyCode === 9 || e.keyCode === 16 || e.keyCode === 18 || e.keyCode === 17 || e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 38 || e.keyCode === 40 )
		{
			return false;
		}

		let zipcode = e.currentTarget.value.replace(/[^0-9]/g, "");

		if( zipcode.length < 8 )
		{
			return false;
		}

		this.setState({
			isSendingZipcode: true,
		});

		webserviceService.findZipcode({
			zipcode: zipcode
		})
		.then((response) => {
			this.setState({
				isSendingZipcode: false,
				cities          : [
					{
						uuid      : response.data.city_id,
						state_id  : response.data.state_id,
						name      : response.data.city_name,
						full_name : response.data.city_full_name,
						state_abbr: response.data.state_abbr,
						state_name: response.data.state_name,
					}
				],
			}, () => {
				this.form.setFieldsValue({
					street  : response.data.street,
					district: response.data.district,
					city_id : response.data.city_id,
				});
			});
		})
		.catch((data) => {
			this.setState({
				isSendingZipcode: false,
			});
		});
	};

	render() {
		const {visible} = this.props;

		const {uuid, isLoading, isSending, isSendingZipcode, cities, citiesIsLoading} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar registro [${uuid}]`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Divider orientation="left">Dados do empresa</Divider>
					<Form.Item name="name" label="Nome fantasia" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Input />
					</Form.Item>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<Form.Item name="document" label="CNPJ" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={[/[0-9]/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12}>
							<Form.Item name="phone" label="Telefone" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={12} sm={8} xl={6}>
							<Form.Item name="zipcode" label="CEP" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
									className="ant-input"
									onKeyUp={this.zipcodeKeyUp}
								/>
							</Form.Item>
							{isSendingZipcode && <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} style={{position: "absolute", top: "37px", left: "100%"}} />}
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={12} xl={10}>
							<Form.Item name="street" label="Endereço" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Input maxLength={100} />
							</Form.Item>
						</Col>
						<Col xs={10} sm={5}>
							<Form.Item name="number" label="Número" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<InputNumber min={1} maxLength={7} style={{width: '100%'}} />
							</Form.Item>
						</Col>
						<Col xs={14} sm={7} xl={9}>
							<Form.Item name="complement" label="Complemento">
								<Input maxLength={50} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<Form.Item name="district" label="Bairro" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Input maxLength={50} />
							</Form.Item>
						</Col>
						<Col xs={24} sm={12}>
							<Form.Item name="city_id" label="Cidade" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Select
									filterOption={false}
									allowClear
									placeholder="Pesquise a cidade"
									notFoundContent={citiesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
									onSearch={this.fetchCities}
									showSearch>
									{cities.map((item, index) => (
										<Select.Option key={index} value={item.uuid}>{item.full_name}</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Divider orientation="left">Dados do responsável</Divider>
					<Form.Item name="responsible" label="Nome" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Input />
					</Form.Item>
					<Form.Item name="responsible_email" label="E-mail" hasFeedback rules={[{required: true, message: "Campo obrigatório."}, {type: "email", message: "Informe um e-mail válido."}]}>
						<Input />
					</Form.Item>
					<Row gutter={16}>
						<Col xs={24} sm={12}>
							<Form.Item name="responsible_document" label="CPF" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={12}>
							<Form.Item name="responsible_phone" label="Telefone" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<MaskedInput
									mask={(rawValue) => {
										if( rawValue.replace(/[^0-9,]/g, "").length > 10 )
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
										else
										{
											return ["(", /[1-9]/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
										}
									}}
									className="ant-input"
								/>
							</Form.Item>
						</Col>
					</Row>
					<Divider />
					<Form.Item name="is_active" label="Ativo" valuePropName="checked">
						<Switch />
					</Form.Item>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
