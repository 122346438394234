import React, { createRef } from 'react'
import { Button, DatePicker, Form } from 'antd'
import MaskedInput from 'react-text-mask'
import moment from 'moment'

const styleArrow = { height: "20px", lineHeight: "0", paddingBottom: "0", paddingTop: "0" }

function DatePickerWrapper({
  subDay,
  addDay,
  disableDateBefore = null,
  disableDateAfter = null,
  customDisable = null,
  customDisableDate = null,
  onKeyUp = null,
  disabled = false,
  onChange = null,
  showTime = false,
  // value = null,
  arrows = false,
  ...restProps
}) {
  return (
    <DatePicker
      {...restProps}
      disabled={disabled}
      inputRender={({ ref, ...props }) => (
        <>
          <MaskedInput
            {...props}
            onKeyUp={onKeyUp}
            mask={
              showTime
                ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]
                : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
            }
            // onChange={(v) => (value = v.target.value)}
          />
          {arrows && (
            <>
              <Button type="link" icon={<i className="fas fa-chevron-left" />} onClick={subDay} style={styleArrow} />
              <Button type="link" icon={<i className="fas fa-chevron-right" />} onClick={addDay} style={styleArrow} />
            </>
          )}
        </>
      )}
      showTime={showTime && { defaultValue: moment('00:00:00', 'HH:mm:ss') }}
      format={showTime ? 'DD/MM/YYYY HH:mm:SS' : 'DD/MM/YYYY'}
      style={{ width: '100%' }}
      disabledDate={(currentDate) =>
        (disableDateBefore || disableDateAfter) &&
        (disableDateAfter
          ? currentDate.isAfter(customDisableDate ? moment(customDisableDate) : moment(), customDisable ?? 'day')
          : currentDate.isBefore(customDisableDate ? moment(customDisableDate) : moment(), customDisable ?? 'day'))
      }
      onChange={onChange}
    />
  )
}

function DatePickerWithMask({
  label = '',
  name = '',
  required = null,
  initialFormValue = undefined,
  isForm = true,
  rules = null,
  value: valueOriginal,
  ...restProps
 }) {  
  const datepicker = createRef();

  return isForm ? (
    <Form.Item
      name={name}
      label={label}
      rules={
        rules !== null
          ? rules
          : [{ required, message: 'Campo obrigatório.' }]
      }
      initialValue={initialFormValue}
      hasFeedback
    >
      <DatePickerWrapper ref={datepicker} {...restProps} value={valueOriginal} />
    </Form.Item>
  ): <DatePickerWrapper ref={datepicker} {...restProps} defaultValue={valueOriginal} />
 }

export default DatePickerWithMask
