import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Checkbox, DatePicker, Form, Tabs, Input, InputNumber, message, Modal, Row, Select, Spin, Switch } from "antd";
import MaskedInput from "react-text-mask"

import moment from "moment";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { resalesService, webserviceService } from "./../../redux/services";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UIUpload,
} from "./../../components";
import { set } from "lodash";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

import Adresses from "./adresses/index";
import Contacts from "./contacts/index";
import Clients from "./clients/index";
import Users from "./users/index";
class Edit extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			isSending: false,
			uuid: 0,
			isSendingZipcode: false,
			cities: [],
			citiesIsLoading: false,
			tipo_pessoa: null,
			status: null,
		};

		this._axiosCancelToken = null;
	}

	fetchCities = (value) => {
		if (this._axiosCancelToken) {
			this._axiosCancelToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				citiesIsLoading: false,
				cities: [],
			});

			return false;
		}

		this.setState({
			citiesIsLoading: true,
		});

		webserviceService.getCities({
			search: value,
			cancelToken: this._axiosCancelToken.token,
		})
			.then((response) => {
				this.setState({
					citiesIsLoading: false,
					cities: response.data.data,
				});
			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					citiesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid: uuid,
		});

		resalesService.show({ uuid })
			.then((response) => {
				let item = response.data.data;

				let cities = [];

				if (item.city) {
					cities.push({
						uuid: item.city.uuid,
						name: item.city.name,
						full_name: item.city.full_name,
						state_id: item.city.state.uuid,
						state_abbr: item.city.state.abbr,
						state_name: item.city.state.name,
					});
				}

				this.setState({
					isLoading: false,
					cities: cities,
				}, () => {
					// Fill form
					this.fillForm(item);
					this.tabAdresses && this.tabAdresses.init(item);
					this.tabContacts && this.tabContacts.init(item);
					this.tabClients && this.tabClients.init(item);
					this.tabUsers && this.tabUsers.init(item);
				});
			})
			.catch((data) => {
				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
					onOk: () => {
						// Force close
						return this.onClose();
					}
				});
			});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			nome: data.nome,
			tipo_pessoa: data.tipo_pessoa,
			cpf: data.cpf,
			cnpj: data.cnpj,
			observacoes: data.observacoes,
			vencimento: data.vencimento ? moment(data.vencimento) : null,
			ie: data.ie,
			im: data.im,
			rg: data.rg,
			nascimento: data.nascimento ? moment(data.nascimento) : null,
			status: data.status,
		});

		this.setState({
			tipo_pessoa: data.tipo_pessoa,
			status: data.status == "Ativo" ? true : false,
		})

		// Upload
		if (data.avatar) {
			this.upload.setFiles([
				{
					uuid: data.uuid,
					url: data.avatar,
					type: 'image/jpeg',
				}
			]);
		}
	};

	resetFields = () => {
		this.form.resetFields();
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		// const file = this.upload.getFiles();

		// if (file.hasError) {
		// 	Modal.error({
		// 		title: "Ocorreu um erro!",
		// 		content: file.error,
		// 	});

		// 	return false;
		// }

		this.setState({
			isSending: true,
		});

		const { uuid } = this.state;

		const data = {
			...values
		};

		// uuid
		data.uuid = uuid;

		// File
		// if (file.files.length) {
		// 	if (!file.files[0].uuid) {
		// 		data.avatar = file.files[0];
		// 	}
		// }
		// else {
		// 	data.avatar = null;
		// }

		// birth
		if (data.nascimento) {
			data.nascimento = data.nascimento.format("YYYY-MM-DD");
		} else {
			delete data.nascimento;
		}

		if (data.vencimento) {
			data.vencimento = data.vencimento.format("YYYY-MM-DD");
		}

		if (data.tipo_pessoa == "Física") {
			delete data.im;
			delete data.ie;
			delete data.cnpj;
			data.razao_social = data.nome;
		} else {
			delete data.cpf;
			delete data.nascimento;
			delete data.rg;
		}

		resalesService.edit(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Registro atualizado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { uuid, isLoading, isSending, tipo_pessoa, status } = this.state;
		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar Revenda`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Tabs defaultActiveKey="general" >
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input />
									</Form.Item>
								</Col>

							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								{tipo_pessoa != null && tipo_pessoa == "Física" ? (
									<Col xs={24} sm={12}>
										<Form.Item name="cpf" label="CPF" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<MaskedInput
												mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
								) : (tipo_pessoa && tipo_pessoa.length > 0 && (
									<Col xs={24} sm={12}>
										<Form.Item name="cnpj" label="CNPJ" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
								))}
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<Row gutter={16}>
									<Col xs={24} sm={12}>
										<Form.Item name="rg" label="RG" hasFeedback >
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12}>
										<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.observacoes.focus()
											}
										}} name="nascimento" label="Data de nascimento" disableDateAfter={true} />
									</Col>
								</Row>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
							))}
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="observacoes" label="Observações">
										<TextArea ref={el => this.observacoes = el} autoSize={{ minRows: 2, maxRows: 5 }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.status.focus()
										}
									}} name="vencimento" label="Data de vencimento" required={true} disableDateBefore={true} />
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="status" label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											ref={el => this.status = el}
											allowClear
											showSearch
										>
											<Select.Option value="Ativo">Ativo</Select.Option>
											<Select.Option value="Inativo">Inativo</Select.Option>
											<Select.Option value="Bloqueado">Bloqueado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="adresses">
							<Adresses
								ref={el => this.tabAdresses = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="anamnese">
							<Contacts
								ref={el => this.tabContacts = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Clientes" key="clients">
							<Clients
								ref={el => this.tabClients = el}
							/>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Usuários" key="users">
							<Users
								ref={el => this.tabUsers = el}
							/>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm >
		)
	}
}

export default Edit;
