import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { customerService, resalesService } from "../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;

class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};


	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,
			resalesIsLoading: false,
			resales: [],
			tipo_pessoa: null,
		};

		this._axiosCancelResalesToken = null;
	}



	onOpen = () => {
		this.setState({
			tipo_pessoa: null,
		});

		setTimeout(() => {
			this.nome && this.nome.focus()
			this.form.setFieldsValue({
				status: "Ativo",
			})

		}, 100)
	};


	fetchResales = (value) => {
		if (this._axiosCancelResalesToken) {
			this._axiosCancelResalesToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelResalesToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				resalesIsLoading: false,
				resales: [],
			});

			return false;
		}

		this.setState({
			resalesIsLoading: true,
		});

		resalesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelResalesToken.token,
		})
			.then((response) => {
				this.setState({
					resalesIsLoading: false,
					resales: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					resalesIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		if (data.vencimento) {
			data.vencimento = data.vencimento.format("YYYY-MM-DD");
		} else {
			delete data.vencimento;
		}

		if (data.nascimento) {
			data.nascimento = data.nascimento.format("YYYY-MM-DD");
		} else {
			delete data.nascimento;
		}

		if (data.app_funcionario == null) {
			data.app_funcionario = 0
		}

		if (data.app_empresa == null) {
			data.app_empresa = 0
		}

		if (data.tipo_pessoa == "Física") {
			delete data.im
			delete data.ie
			delete data.cnpj
		} else {
			delete data.cpf
			delete data.nascimento
			delete data.rg
		}

		customerService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Cliente cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, nextOrder, resalesIsLoading, resales, tipo_pessoa } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Novo Cliente">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						order: nextOrder,
						required: true,
						is_active: true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>

							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="limite_funcionario" label="Limite de funcionários" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<InputNumber style={{ width: '100%' }} />
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="slug" label={<UILabelHelp title="Slug" content={"URL da página relativo ao site\nex.: https://slug.byponto.com.br"} />} hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input onKeyUp={(evt) => {
											this.form.setFieldsValue({ slug: evt.currentTarget.value.replace(/[^0-9_a-z]/g, "") })

										}} maxLength={30} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="revenda_id" label="Revenda" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											filterOption={false}
											allowClear
											placeholder="Escolha a revenda"
											notFoundContent={resalesIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
											onSearch={this.fetchResales}
											showSearch
										>
											{resales.map((item, index) => (
												<Select.Option key={index} value={item.id}>
													{item.nome}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<Row gutter={16}>
									<Col xs={24} sm={8}>
										<Form.Item name="rg" label="RG" hasFeedback >
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={8}>
										<Form.Item name="cpf" rules={[{ required: true, message: "Campo obrigatório." }]} label="CPF" hasFeedback >
											<MaskedInput
												mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={8}>
										<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.tipo_contrato.focus()
											}
										}} label="Data de nascimento" name="nascimento" disableDateAfter={true} />
									</Col>
								</Row>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
								<>
									<Row gutter={16}>
										<Col xs={24} sm={8}>
											<Form.Item name="cnpj" label="CNPJ" rules={[{ required: true, message: "Campo obrigatório." }]} hasFeedback >
												<MaskedInput
													mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
													className="ant-input"
												/>
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={8}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
								</>
							))}
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_contrato" label="Tipo de contrato" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											ref={el => this.tipo_contrato = el}
											showSearch>
											<Select.Option value="Mensal">Mensal</Select.Option>
											<Select.Option value="Anual">Anual</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.observacoes.focus()
										}
									}} label="Data de vencimento" name="vencimento" disableDateBefore={true} required={true} />
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="observacoes" label="Observações">
										<TextArea ref={(el) => this.observacoes = el} autoSize={{ minRows: 2, maxRows: 5 }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col sm={8}>
									<Form.Item name="status" label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											showSearch
										>
											<Select.Option value="Ativo">Ativo</Select.Option>
											<Select.Option value="Inativo">Inativo</Select.Option>
											<Select.Option value="Bloqueado">Bloqueado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="app_funcionario" label="App Funcionário" >
										<Switch />
									</Form.Item>
								</Col>
								<Col sm={8}>
									<Form.Item name="app_empresa" label="App Empresa">
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="alert-exams">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="anamnese">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Usuários" key="client-users">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
