import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Col, Divider, Form, Input, message, Modal, Row, Select, Spin, Switch } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { professionalService, tenantService, tenantUserFunctionService, tenantUserTypeService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading       : true,
			isSending       : false,
			passwordRandom  : true,
			tenantsIsLoading: false,
			tenants         : [],
			types           : [],
			functions       : [],
		};

		this._axiosCancelTenantsToken = null;
	}

	fetchTenants = (value) => {
		if( this._axiosCancelTenantsToken )
		{
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				tenantsIsLoading: false,
				tenants         : [],
			});

			return false;
		}

		this.setState({
			tenantsIsLoading: true,
		});

		tenantService.getAutocomplete({
			search     : value,
			orderBy    : "name:asc",
			is_active  : 1,
			cancelToken: this._axiosCancelTenantsToken.token,
		})
		.then((response) => {
			this.setState({
				tenantsIsLoading: false,
				tenants         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				tenantsIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onOpen = () => {
		this.setState({
			isLoading: true,
		});

		let functions = [];

		tenantUserFunctionService.getAutocomplete({
			is_active: 1,
			orderBy  : `name:asc`,
		})
		.then((response) => {
			functions = response.data.data;

			return tenantUserTypeService.getAutocomplete({
				is_active: 1,
				orderBy  : `name:asc`,
			})
		})
		.then((response) => {
			this.setState({
				isLoading: false,
				functions: functions,
				types    : response.data.data,
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			tenants  : [],
			functions: [],
			types    : [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = {...values};

		professionalService.create(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success("Registro cadastrado com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const {visible} = this.props;

		const {isLoading, isSending, passwordRandom, tenants, tenantsIsLoading, types, functions} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Inserir novo registro">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						password_random: true,
						is_active      : true,
					}}>
					<Form.Item name="tenant_id" label="Empresa" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							filterOption={false}
							allowClear
							placeholder="Pesquise a empresa"
							notFoundContent={tenantsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
							onSearch={this.fetchTenants}
							showSearch>
							{tenants.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>{item.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="user_function_id" label="Função" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							optionFilterProp="children"
							filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
							allowClear
							placeholder="Selecione a função"
							showSearch>
							{functions.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>{item.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="user_type_id" label="Tipo" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							optionFilterProp="children"
							filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
							allowClear
							placeholder="Selecione o tipo"
							showSearch>
							{types.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>{item.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="name" label="Nome" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Input />
					</Form.Item>
					<Form.Item name="email" label="E-mail" hasFeedback rules={[{required: true, message: "Campo obrigatório."}, {type: "email", message: "Informe um e-mail válido."}]}>
						<Input />
					</Form.Item>
					<Form.Item name="password_random" label="Gerar senha aleatória" valuePropName="checked">
						<Switch onChange={(checked) => this.setState({passwordRandom: checked})} />
					</Form.Item>
					{!passwordRandom && (
						<Row gutter={16}>
							<Col xs={24} sm={12}>
								<Form.Item name="password" label="Senha" hasFeedback rules={[
									{required: true, message: "Campo obrigatório."},
									{min: 6, message: "Deve conter no mínimo 6 caracteres."},
								]}>
									<Input type="password" />
								</Form.Item>
							</Col>
							<Col xs={24} sm={12}>
								<Form.Item
									name="password_confirmation"
									label="Confirmar senha"
									hasFeedback
									dependencies={['password']}
									rules={[
										{required: true, message: "Campo obrigatório."},
										({getFieldValue}) => ({
											validator(rule, value) {
												if( !value || getFieldValue('password') === value )
												{
													return Promise.resolve();
												}

												return Promise.reject("Deve conter o mesmo valor de Nova senha.");
											},
										}),
									]}>
									<Input type="password" />
								</Form.Item>
							</Col>
						</Row>
					)}
					<Divider />
					<Form.Item name="is_active" label="Ativo" valuePropName="checked">
						<Switch />
					</Form.Item>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
