import React, { Component } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { NavLink, withRouter } from "react-router-dom";
import { Menu } from "antd";

const SubMenu = Menu.SubMenu;

class MainNavigation extends Component {
	static propTypes = {
		onClick: PropTypes.func,
	};

	static defaultProps = {
		onClick: () => null,
	};

	render() {
		const { location } = this.props;
		let base = "";
		let defaultOpenKeys = [];
		let selectedKeys = [];
		let paths = location.pathname.split("/").filter(function (el) {
			return el;
		});

		let pathsGroups = "";

		paths.forEach((path, index) => {
			if (path) {
				if (index === 0) {
					base = `/${path}`;
				}

				pathsGroups += `/${path}`;

				defaultOpenKeys.push(pathsGroups);
			}
		});

		selectedKeys.push(location.pathname);
		selectedKeys.push(base);

		return (
			<Menu theme="dark" mode="inline" defaultSelectedKeys={selectedKeys} defaultOpenKeys={defaultOpenKeys} selectedKeys={selectedKeys} onClick={this.props.onClick}>
				<Menu.Item key="/" icon={<i className="fal fa-tachometer-fast" />}>
					<NavLink to="/">
						Início
					</NavLink>
				</Menu.Item>
				{(
					this.props.permissions.includes("alert-exams.list")
					|| this.props.permissions.includes("anamnese.list")
					|| this.props.permissions.includes("exams-categories.list")
					|| this.props.permissions.includes("roles.list")
					|| this.props.permissions.includes("log.list")
					|| this.props.permissions.includes("system-log.list")
					|| this.props.permissions.includes("customers.list")
					|| this.props.permissions.includes("tenant-user-functions.list")
					|| this.props.permissions.includes("tenant-user-types.list")
					|| this.props.permissions.includes("settings-certificates.show")
					|| this.props.permissions.includes("users.list")) && (
						<SubMenu key="/administrator" title="Configurações" icon={<i className="fal fa-sliders-v" />}>
							{this.props.permissions.includes("users.list") && <Menu.Item key="/administrator/users">
								<NavLink to="/administrator/users">
									Usuários
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("roles.list") && <Menu.Item key="/administrator/roles-and-permissions">
								<NavLink to="/administrator/roles-and-permissions">
									Papéis e Permissões
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("log.list") && <Menu.Item key="/administrator/logs">
								<NavLink to="/administrator/logs">
									Logs de Alteração
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("system-log.list") && <Menu.Item key="/administrator/system-log">
								<NavLink to="/administrator/system-log">
									Logs de Erro
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("settings-certificates.show") && (
							<Menu.Item key="/settings-certificates/certificate">
								<NavLink to="/settings-certificates/certificate">
									Certificado
								</NavLink>
							</Menu.Item>
							)}
						</SubMenu>
					)}
				{(
					this.props.permissions.includes("about-app.list")
					|| this.props.permissions.includes("faq.edit")
					|| this.props.permissions.includes("privacy-policy.edit")
					|| this.props.permissions.includes("terms-of-use.edit")) && (
						<SubMenu key="/institutional" title="Institucional" icon={<i className="fal fa-file-alt" />}>
							{this.props.permissions.includes("about-app.edit") && <Menu.Item key="/institutional/about-app">
								<NavLink to="/institutional/about-app">
									Sobre a ByPonto
								</NavLink>
							</Menu.Item>}
							<SubMenu key="/institutional/funcionario" title="Funcionário">
								{this.props.permissions.includes("privacy-policy.edit") && <Menu.Item key="/institutional/privacy-policy/funcionario">
									<NavLink to="/institutional/privacy-policy/funcionario">
										Política de Privacidade
									</NavLink>
								</Menu.Item>}
								{this.props.permissions.includes("terms-of-use.edit") && <Menu.Item key="/institutional/terms-of-use/funcionario">
									<NavLink to="/institutional/terms-of-use/funcionario">
										Termos de Uso
									</NavLink>
								</Menu.Item>}
								{this.props.permissions.includes("faq.edit") && <Menu.Item key="/institutional/faq/funcionario">
									<NavLink to="/institutional/faq/funcionario">
										Perguntas Frequentes
									</NavLink>
								</Menu.Item>}
							</SubMenu>
							<SubMenu key="/institutional/empresa" title="Empresa">
								{this.props.permissions.includes("privacy-policy.edit") && <Menu.Item key="/institutional/privacy-policy/empresa">
									<NavLink to="/institutional/privacy-policy/empresa">
										Política de Privacidade
									</NavLink>
								</Menu.Item>}
								{this.props.permissions.includes("terms-of-use.edit") && <Menu.Item key="/institutional/terms-of-use/empresa">
									<NavLink to="/institutional/terms-of-use/empresa">
										Termos de Uso
									</NavLink>
								</Menu.Item>}
								{this.props.permissions.includes("faq.edit") && <Menu.Item key="/institutional/faq/empresa">
									<NavLink to="/institutional/faq/empresa">
										Perguntas Frequentes
									</NavLink>
								</Menu.Item>}
							</SubMenu>
						</SubMenu>
					)}
				{(
					this.props.permissions.includes("customers.list")
					|| this.props.permissions.includes("professionals.list")
					|| this.props.permissions.includes("tenants.list")) && (
						<>
							{this.props.permissions.includes("customers.list") && <Menu.Item icon={<i className="fal fa-user" />} key="/list/customers">
								<NavLink to="/list/customers">
									Clientes
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("customers.list") && <Menu.Item icon={<i className="fal fa-hdd" />} key="/list/equipments">
								<NavLink to="/list/equipments">
									Equipamentos
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("customers.list") && <Menu.Item icon={<i className="fal fa-ballot-check" />} key="/list/resales">
								<NavLink to="/list/resales">
									Revendas
								</NavLink>
							</Menu.Item>}
						</>
						// <SubMenu key="/list" title="Cadastros" icon={<i className="fal fa-user" />}>
						// 	{this.props.permissions.includes("tenants.list") && <Menu.Item key="/list/tenants">
						// 		<NavLink to="/list/tenants">
						// 			Empresas
						// 		</NavLink>
						// 	</Menu.Item>}

						// 	{this.props.permissions.includes("professionals.list") && <Menu.Item key="/list/professionals">
						// 		<NavLink to="/list/professionals">
						// 			Profissionais
						// 		</NavLink>
						// 	</Menu.Item>}
						// </SubMenu>
					)}
				{(
					this.props.permissions.includes("customers-deleted.list")
					|| this.props.permissions.includes("professionals-deleted.list")
					|| this.props.permissions.includes("tenants-deleted.list")) && (
						<SubMenu key="/list-deleted" title="Cadastros excluídos" icon={<i className="fal fa-user-slash" />}>
							{this.props.permissions.includes("tenants-deleted.list") && <Menu.Item key="/list-deleted/tenants">
								<NavLink to="/list-deleted/tenants">
									Empresas
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("customers-deleted.list") && <Menu.Item key="/list-deleted/customers">
								<NavLink to="/list-deleted/customers">
									Pacientes
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("professionals-deleted.list") && <Menu.Item key="/list-deleted/professionals">
								<NavLink to="/list-deleted/professionals">
									Profissionais
								</NavLink>
							</Menu.Item>}
						</SubMenu>
					)}
				{(
					this.props.permissions.includes("exams.list")) && (
						<SubMenu key="/exams" title="Exames" icon={<i className="fal fa-file-medical-alt" />}>
							<Menu.Item key="/exams/medical">
								<NavLink to="/exams/medical">
									Médicos
								</NavLink>
							</Menu.Item>
							<Menu.Item key="/exams/dental">
								<NavLink to="/exams/dental">
									Odontológicos
								</NavLink>
							</Menu.Item>
						</SubMenu>
					)}
				{/* {(
					this.props.permissions.includes("push-general.list")
					|| this.props.permissions.includes("push-city.list")
					|| this.props.permissions.includes("push-state.list")
					|| this.props.permissions.includes("push-user.list")) && (
						<SubMenu key="/push" title={'Push'} icon={<i className="fal fa-bell" />}>
							{this.props.permissions.includes("push-general.list") && <Menu.Item key="/push/general">
								<NavLink to="/push/general">
									Geral
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("push-city.list") && <Menu.Item key="/push/city">
								<NavLink to="/push/city">
									Por cidade
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("push-state.list") && <Menu.Item key="/push/state">
								<NavLink to="/push/state">
									Por estado
								</NavLink>
							</Menu.Item>}
							{this.props.permissions.includes("push-user.list") && <Menu.Item key="/push/user">
								<NavLink to="/push/user">
									Por paciente
								</NavLink>
							</Menu.Item>}
						</SubMenu>
					)} */}
				{this.props.permissions.includes("vaccines.list") && <Menu.Item key="/vaccines" icon={<i className="fal fa-syringe" />}>
					<NavLink to="/vaccines">
						Vacinas
					</NavLink>
				</Menu.Item>}
			</Menu>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps)(withRouter(MainNavigation));
