import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './sass/app.scss';
import './less/antd.less';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
serviceWorker.unregister();
