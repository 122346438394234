import { api } from "./../../config/api";

const basePath = "cliente-roles";

/**
 * Autocomplete
 *
 * @param {Object} options
 *
 * @returns {Promise<T>}
 */
export const getAutocomplete = (options) => {
	return api.get(`${basePath}/${options.client_uuid}/autocomplete`);
};
