import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Col, Form, Input, message, Modal, Row, Switch, Tabs } from "antd";

import { tenantUserTypeService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Create extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
		};
	}

	onOpen = () => {
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = {...values};

		tenantUserTypeService.create(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success("Registro cadastrado com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const {visible} = this.props;

		const {isLoading, isSending} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Inserir novo registro">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						can_create_user: false,
						is_active      : true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Form.Item name="name" label="Nome" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
								<Input maxLength={191} />
							</Form.Item>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="can_create_user" label="Pode cadastrar usuários?" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="is_active" label="Ativo" valuePropName="checked">
										<Switch />
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
