import React, { Component } from "react";
import axios from "axios";
import * as PropTypes from "prop-types";
import { Form, Input, message, Modal, Select, Spin, Switch, Tag } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { professionalService, tenantService, tenantUserFunctionService, tenantUserTypeService } from "./../../redux/services";

import {
	UIDrawerForm,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

class Edit extends Component {
	static propTypes = {
		visible   : PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose   : PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading       : true,
			isSending       : false,
			uuid            : 0,
			tenantsIsLoading: false,
			tenants         : [],
			types           : [],
			functions       : [],
		};

		this._axiosCancelTenantsToken = null;
	}

	fetchTenants = (value) => {
		if( this._axiosCancelTenantsToken )
		{
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if( !value.trim().length )
		{
			this.setState({
				tenantsIsLoading: false,
				tenants         : [],
			});

			return false;
		}

		this.setState({
			tenantsIsLoading: true,
		});

		tenantService.getAutocomplete({
			search     : value,
			orderBy    : "name:asc",
			is_active  : 1,
			cancelToken: this._axiosCancelTenantsToken.token,
		})
		.then((response) => {
			this.setState({
				tenantsIsLoading: false,
				tenants         : response.data.data,
			});
		})
		.catch((data) => {
			if( data.error_type === API_ERRO_TYPE_CANCEL ) return null;

			this.setState({
				tenantsIsLoading: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	onOpen = (uuid) => {
		this.setState({
			isLoading: true,
			uuid     : uuid,
		});

		let functions = [];
		let types     = [];

		tenantUserFunctionService.getAutocomplete({
			is_active: 1,
			orderBy  : `name:asc`,
		})
		.then((response) => {
			functions = response.data.data;

			return tenantUserTypeService.getAutocomplete({
				is_active: 1,
				orderBy  : `name:asc`,
			});
		})
		.then((response) => {
			types = response.data.data;

			return professionalService.show({uuid})
		})
		.then((response) => {
			let item = response.data.data;

			const tenants = [];

			if( item.tenant )
			{
				tenants.push(item.tenant);
			}

			this.setState({
				isLoading: false,
				tenants  : tenants,
				functions: functions,
				types    : types,
			}, () => {
				// Fill form
				this.fillForm(item);
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	fillForm = (data) => {
		this.form.setFieldsValue({
			tenant_id       : data.tenant?.uuid ?? '',
			user_function_id: (data.user_function?.is_active && !data.user_function?.deleted_at) ? data.user_function?.uuid ?? '' : '',
			user_type_id    : (data.user_type?.is_active && !data.user_type?.deleted_at) ? data.user_type?.uuid ?? '' : '',
			name            : data.name,
			email           : data.email,
			is_active       : data.is_active,
		});
	};

	resetFields = () => {
		this.form.resetFields();

		this.setState({
			tenants  : [],
			functions: [],
			types    : [],
		});
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const {uuid} = this.state;

		const data = {...values};

		// uuid
		data.uuid = uuid;

		professionalService.edit(data)
		.then((response) => {
			this.setState({
				isSending: false,
			});

			// Reset fields
			this.resetFields();

			// Success message
			message.success("Registro atualizado com sucesso.");

			// Callback
			this.props.onComplete();
		})
		.catch((data) => {
			this.setState({
				isSending: false,
			});

			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
			});
		});
	};

	render() {
		const {visible} = this.props;

		const {uuid, isLoading, isSending, tenants, tenantsIsLoading, types, functions} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title={`Editar registro [${uuid}]`}>
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}>
					<Form.Item name="tenant_id" label="Empresa" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							filterOption={false}
							allowClear
							placeholder="Pesquise o usuário"
							notFoundContent={tenantsIsLoading ? <Spin indicator={<i className="fad fa-spinner-third fa-spin" />} /> : null}
							onSearch={this.fetchTenants}
							showSearch>
							{tenants.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>
									{item.is_active ? item.name : (
										<Tag style={{margin: 0}} color="#777" title="Inativo">{item.name}</Tag>
									)}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="user_function_id" label="Função" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							optionFilterProp="children"
							filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
							allowClear
							placeholder="Selecione a função"
							showSearch>
							{functions.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>{item.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="user_type_id" label="Tipo" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Select
							optionFilterProp="children"
							filterOption={(input, option) => (typeof option.children === 'string' ? option.children : option.children.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
							allowClear
							placeholder="Selecione o tipo"
							showSearch>
							{types.map((item, index) => (
								<Select.Option key={index} value={item.uuid}>{item.name}</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="name" label="Nome" hasFeedback rules={[{required: true, message: "Campo obrigatório."}]}>
						<Input />
					</Form.Item>
					<Form.Item name="email" label="E-mail" hasFeedback rules={[{required: true, message: "Campo obrigatório."}, {type: "email", message: "Informe um e-mail válido."}]}>
						<Input />
					</Form.Item>
					<Form.Item name="is_active" label="Ativo" valuePropName="checked">
						<Switch />
					</Form.Item>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Edit;
