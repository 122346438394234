import React, { Component } from "react";
import { connect } from "react-redux";
import * as PropTypes from "prop-types";
import { Col, Form, InputNumber, Modal, Row, Switch, Tabs } from "antd";

import moment from "moment";

import { faqService } from "./../../redux/services";

import {
	UIDrawerForm,
	UIRichTextEditor,
} from "./../../components";

class Show extends Component {
	static propTypes = {
		visible : PropTypes.bool.isRequired,
		onClose : PropTypes.func.isRequired,
		external: PropTypes.bool,
		type    : PropTypes.any.isRequired,
	};

	constructor(props) {
		super(props);

		this.stateClean = {
			isLoading: true,
			uuid     : 0,
			item     : {},
		};

		this.state = {
			...this.stateClean,
		};
	}

	onOpen = (uuid) => {
		this.setState({
			...this.stateClean,
		});

		faqService.show({uuid})
		.then((response) => {
			this.setState({
				isLoading: false,
				item     : response.data.data,
			}, () => {
				// Editor
				this.editor.setValue(response.data.data.text);
			});
		})
		.catch((data) => {
			Modal.error({
				title  : "Ocorreu um erro!",
				content: String(data),
				onOk   : () => {
					// Force close
					return this.onClose();
				}
			});
		});
	};

	onClose = () => {
		// Callback
		this.props.onClose();
	};

	render() {
		const {visible} = this.props;

		const {uuid, isLoading, item} = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={500}
				onClose={this.onClose}
				isLoading={isLoading}
				showBtnSave={false}
				title={`Visualizar Pergunta`}>
				<Form layout="vertical">
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Form.Item label="Nome">
								{item.name}
							</Form.Item>
							<UIRichTextEditor
								ref={el => this.editor = el}
								name="text"
								label="Texto"
								disabled
							/>
							<Form.Item label="Ordem">
								{item.order}
							</Form.Item>
							<Form.Item label="Ativo">
								<Switch disabled checked={item.is_active} />
							</Form.Item>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item label="Criação">
										{moment(item.created_at).calendar()}
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item label="Última atualização">
										{moment(item.updated_at).calendar()}
									</Form.Item>
								</Col>
							</Row>
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		permissions: state.auth.userData.permissions,
	};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(Show);
