import React, { Component } from "react";
import * as PropTypes from "prop-types";
import axios from "axios";
import { Col, DatePicker, Alert, Form, Input, Checkbox, InputNumber, Spin, message, Modal, Row, Select, Switch, Tabs } from "antd";

import { API_ERRO_TYPE_CANCEL } from "./../../config/general";

import { resalesService } from "../../redux/services";
import MaskedInput from "react-text-mask"

import moment from "moment";

import {
	DatePickerWithMask,
	UIDrawerForm,
	UILabelHelp,
} from "./../../components";

const formId = `form-drawer-${Math.floor(Math.random() * 10001)}`;

const { TextArea } = Input;
class Create extends Component {
	static propTypes = {
		visible: PropTypes.bool.isRequired,
		onComplete: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isSending: false,
			nextOrder: 1,
			type: null,
			tenantsIsLoading: false,
			tenants: [],
			tipo_pessoa: null,
		};
	}

	onOpen = () => {
		this.setState({
			tipo_pessoa: null,
		});

		setTimeout(() => {
			this.nome && this.nome.focus();
			this.form.setFieldsValue({
				status: "Ativo",
			})
		}, 100)

	};

	fetchTenants = (value) => {
		if (this._axiosCancelTenantsToken) {
			this._axiosCancelTenantsToken.cancel("Only one request allowed at a time.");
		}

		this._axiosCancelTenantsToken = axios.CancelToken.source();

		if (!value.trim().length) {
			this.setState({
				tenantsIsLoading: false,
				tenants: [],
			});

			return false;
		}

		this.setState({
			tenantsIsLoading: true,
		});

		resalesService.getAll({
			search: value,
			// orderBy: "name:asc",
			cancelToken: this._axiosCancelTenantsToken.token,
		})
			.then((response) => {
				this.setState({
					tenantsIsLoading: false,
					tenants: response.data.data,
				});

			})
			.catch((data) => {
				if (data.error_type === API_ERRO_TYPE_CANCEL) return null;

				this.setState({
					tenantsIsLoading: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	resetFields = () => {
	};

	onClose = () => {
		// Reset fields
		this.resetFields();

		// Callback
		this.props.onClose();
	};

	onFinish = (values) => {
		this.setState({
			isSending: true,
		});

		const data = { ...values };

		if (data.nascimento) {
			data.nascimento = data.nascimento.format("YYYY-MM-DD");
		} else {
			delete data.nascimento;
		}

		if (data.vencimento) {
			data.vencimento = data.vencimento.format("YYYY-MM-DD");
		}

		if (data.app_funcionario == null) {
			data.app_funcionario = 0;
		}

		if (data.app_empresa == null) {
			data.app_empresa = 0;
		}

		if (data.tipo_pessoa == "Física") {
			delete data.im;
			delete data.ie;
			delete data.cnpj;
		} else {
			delete data.cpf;
			delete data.nascimento;
			delete data.rg;
		}

		resalesService.create(data)
			.then((response) => {
				this.setState({
					isSending: false,
				});

				// Reset fields
				this.resetFields();

				// Success message
				message.success("Cliente cadastrado com sucesso.");

				// Callback
				this.props.onComplete();
			})
			.catch((data) => {
				this.setState({
					isSending: false,
				});

				Modal.error({
					title: "Ocorreu um erro!",
					content: String(data),
				});
			});
	};

	render() {
		const { visible } = this.props;

		const { isLoading, isSending, nextOrder, tenantsIsLoading, tenants, tipo_pessoa } = this.state;

		return (
			<UIDrawerForm
				visible={visible}
				width={700}
				onClose={this.onClose}
				isLoading={isLoading}
				isSending={isSending}
				formId={formId}
				title="Incluir Nova Revenda">
				<Form
					ref={el => this.form = el}
					id={formId}
					layout="vertical"
					scrollToFirstError
					onFinish={this.onFinish}
					initialValues={{
						order: nextOrder,
						required: true,
						is_active: true,
					}}>
					<Tabs defaultActiveKey="general">
						<Tabs.TabPane forceRender tab="Info. Gerais" key="general">
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="nome" label="Nome" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Input ref={el => this.nome = el} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<Form.Item name="tipo_pessoa" label="Tipo de pessoa" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											allowClear
											onChange={(value) => { this.setState({ tipo_pessoa: value }) }}
											showSearch>
											<Select.Option value="Física">Física</Select.Option>
											<Select.Option value="Jurídica">Jurídica</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								{tipo_pessoa != null && tipo_pessoa == "Física" ? (
									<Col xs={24} sm={12}>
										<Form.Item name="cpf" label="CPF" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]} >
											<MaskedInput
												mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
								) : (tipo_pessoa && tipo_pessoa.length > 0 && (
									<Col xs={24} sm={12}>
										<Form.Item name="cnpj" label="CNPJ" hasFeedback rules={[{ required: true, message: "Campo obrigatório." }]} >
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
								))}
							</Row>
							{tipo_pessoa != null && tipo_pessoa == "Física" ? (
								<Row gutter={16}>
									<Col xs={24} sm={12}>
										<Form.Item name="rg" label="RG" hasFeedback >
											<MaskedInput
												mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/]}
												className="ant-input"
											/>
										</Form.Item>
									</Col>
									<Col xs={24} sm={12}>
										<DatePickerWithMask onKeyUp={(e) => {
											if (e.keyCode === 13 || e.keyCode === 9) {
												this.observacoes.focus()
											}
										}} name="nascimento" label="Data de nascimento" disableDateAfter={true} />
									</Col>
								</Row>
							) : (tipo_pessoa && tipo_pessoa.length > 0 && (
									<Row gutter={16}>
										<Col xs={24} sm={12}>
											<Form.Item name="ie" label="IE" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
										<Col xs={24} sm={12}>
											<Form.Item name="im" label="IM" hasFeedback >
												<Input style={{ width: '100%' }} />
											</Form.Item>
										</Col>
									</Row>
							))}
							<Row gutter={16}>
								<Col xs={24} sm={24}>
									<Form.Item name="observacoes" label="Observações">
										<TextArea ref={el => this.observacoes = el} autoSize={{ minRows: 2, maxRows: 5 }} />
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col xs={24} sm={12}>
									<DatePickerWithMask onKeyUp={(e) => {
										if (e.keyCode === 13 || e.keyCode === 9) {
											this.status.focus()
										}
									}} name="vencimento" label="Data de vencimento" required={true} disableDateBefore={true} />
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item name="status" label="Status" rules={[{ required: true, message: "Campo obrigatório." }]}>
										<Select
											optionFilterProp="children"
											filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
											ref={el => this.status = el}
											allowClear
											showSearch
										>
											<Select.Option value="Ativo">Ativo</Select.Option>
											<Select.Option value="Inativo">Inativo</Select.Option>
											<Select.Option value="Bloqueado">Bloqueado</Select.Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							{/* <Row gutter={16}>
								<Col sm={12}>
									<Form.Item name="app_funcionario" label="App Funcionário" >
										<Switch />
									</Form.Item>
								</Col>
								<Col sm={12}>
									<Form.Item name="app_empresa" label="App Empresa">
										<Switch />
									</Form.Item>
								</Col>
							</Row> */}
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Endereços" key="alert-exams">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Contatos" key="anamnese">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
						<Tabs.TabPane forceRender tab="Clientes" key="clients">
							<Alert message="Salve o item atual parar liberar este conteúdo para edição." type="info" showIcon icon={<i className="fas fa-info-circle" />} />
						</Tabs.TabPane>
					</Tabs>
				</Form>
			</UIDrawerForm>
		)
	}
}

export default Create;
