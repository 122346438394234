import React, { Component } from "react";
import { DatePicker, Row, Col, Form, Input } from "antd";
import MaskedInput from "react-text-mask"
import moment from "moment";

class DateWithEndPickerMask extends Component {
    static defaultProps  = {
        filters: null,
        setFilter: null,
        label: "",
        name: "",
        disableDateBefore: null,
        disableDateAfter: null,
        required: null,
    }
    constructor(props) {
        super(props);


        this.state = {
            date1: null,
            date2: null,
        }
    }
    // setFilter("created_at", date ?? null)
    render() {
        const { filters, setFilter } = this.props;
        const { date1, date2 } = this.state;
        return (
            <>
                {/* <Row gutter={16}>
                    <Col xs={12}>
                        <DatePicker
                            inputRender={(props) =>
                                <MaskedInput
                                    onKeyUp={this.props.onKeyUp}
                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                    onChange={(v) => this.setState({date1: v.target.value})}
                                    {...props}
                                    placeholder="Data Inicial"
                                />
                            }
                            onClick={(v) => console.log("AQUI: ", v.target.value)}
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            disabledDate={(currentDate) => currentDate.isAfter(moment(), "day")}
                        />
                    </Col>
                    <Col xs={12}>
                        <DatePicker
                            inputRender={(props) =>
                                <MaskedInput
                                    onKeyUp={this.props.onKeyUp}
                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                    onChange={(v) => date2 = v.target.value}
                                    placeholder="Data Final"
                                    {...props}
                                />}
                            format="DD/MM/YYYY"
                            style={{ width: "100%" }}
                            disabledDate={(currentDate) => currentDate.isBefore(moment(this.state.date1), "day")}
                        />
                    </Col>
                </Row> */}
                <DatePicker.RangePicker
                    format="DD/MM/YYYY"
                    value={filters.created_at}
                    inputRender={() => [<h1>asd</h1>, <h1>asds</h1>]}
                    render={<h2>asd</h2>}
                    onChange={(date, dateString) => setFilter("created_at", date ?? null)}
                    disabledDate={(currentDate) => currentDate.isAfter(moment(), "day")}
                />
            </>
        )
    }
}

export default DateWithEndPickerMask;